const Conditions = [
  'Anxiety',
  'Cancer',
  'Epilepsy',
  'Glaucoma',
  'Migraines',
  'Persistent Pain',
  'Muscle Spasms',
  'Multiple Sclerosis',
  'Parkinson\'s Disease',
  'Tourette\'s Syndrome',
  'Psychiatric Disorders including PTSD',
  'Acquired Immune Deficiency Syndrome (AIDS)',
  'Dependence on Prescription Meds',
  'A Terminal Illness',
  'Hepatitis C',
  'Amyotrophic Lateral Sclerosis',
  'IBS/Crohn\'s Disease',
  'Huntington\'s Disease',
  'Autism',
  'Neuropathies',
  'Sickle Cell Anemia',
  'Alzheimer\'s Disease',
  'Cachexia & Wasting Syndrome',
  'Other Chronic or Debilitation Condition',
  'Unsure/My Condition Not Listed',
];

export default Conditions;
