import {
  RenewalDetails,
  ScheduleAppointmentDetails,
  UpgradeBody,
} from "./Types";

const apiHostname = process.env.REACT_APP_API_HOSTNAME;

export interface AvailabilityDate {
  date: string;
  times: string[];
}

export interface Availability {
  dates: AvailabilityDate[];
  hasNextPage: boolean;
}

export async function GetAvailability(
  pageCount: number,
  pageNumber: number,
  jurisdiction: string
) {
  const datesRequest = await fetch(
    `${apiHostname}/api/GetAvailability?pageCount=${pageCount}&pageNumber=${pageNumber}&jurisdiction=${jurisdiction}`
  );
  const daysJson = (await datesRequest.json()) as Availability;
  // const days = daysJson.map((day: string) => (new Date(Date.parse(`${day}T00:00:00`))));
  return daysJson;
}

export async function StartBooking(): Promise<string> {
  const bookingRequest = await fetch(`${apiHostname}/api/StartBooking`);
  const bookingResJson = await bookingRequest.json();
  const { formUrl } = bookingResJson;
  return formUrl;
}

export async function ScheduleAppointment(
  appointmentDetails: ScheduleAppointmentDetails
) {
  const finalizeBookingRequest = await fetch(
    `${apiHostname}/api/ScheduleAppointment`,
    {
      method: "POST",
      body: JSON.stringify(appointmentDetails),
    }
  );
  const reqJson = await finalizeBookingRequest.json();

  return reqJson;
}

export async function ScheduleRenewal(renewalDetails: RenewalDetails) {
  const finalizeBookingRequest = await fetch(
    `${apiHostname}/api/ScheduleRenewal`,
    {
      method: "POST",
      body: JSON.stringify(renewalDetails),
    }
  );
  const reqJson = await finalizeBookingRequest.json();

  return reqJson;
}

export async function OrderUpgrade(upgradeBody: UpgradeBody) {
  const finalizeBookingRequest = await fetch(
    `${apiHostname}/api/OrderUpgrade`,
    {
      method: "POST",
      body: JSON.stringify(upgradeBody),
    }
  );
  const reqJson = await finalizeBookingRequest.json();

  return reqJson;
}

export async function CheckCode(
  code: string,
  jurisdiction: string,
  appointmentType: string
) {
  const checkCodeRequest = await fetch(
    `${apiHostname}/api/CheckCode?certificate=${code}&jurisdiction=${jurisdiction}&appointmentType=${appointmentType}`
  );
  if (checkCodeRequest.ok) {
    return checkCodeRequest.json();
  }

  return Promise.reject(new Error("Invalid promo code."));
}

export async function CheckRenewalToken(token: string, email: string) {
  const checkCodeRequest = await fetch(
    `${apiHostname}/api/CheckRenewalToken?token=${token}&email=${email}`
  );
  if (checkCodeRequest.ok) {
    // return checkCodeRequest.json();
    return true;
  }

  // return Promise.reject(new Error('Unable to verify token.'));
  // return false;
  // just return true for now - I accidentally sent the wrong URLs
  return true;
}

export async function GetStartedRapidReturn(
  token: string,
  jurisdiction: string,
  email?: string
) {
  const response = await fetch(`${apiHostname}/api/GetStartedRapidReturn`, {
    method: "POST",
    body: JSON.stringify({
      token,
      email,
      jurisdiction,
    }),
  });

  if (response.ok) {
    return true;
  }
  return false;
}

export async function CheckUpgradeToken(appointmentId: string, token: string) {
  const checkCodeRequest = await fetch(
    `${apiHostname}/api/CheckUpgradeToken?appointmentId=${appointmentId}&token=${token}`
  );
  if (checkCodeRequest.ok) {
    // return checkCodeRequest.json();
    return true;
  }

  // return Promise.reject(new Error('Unable to verify token.'));
  return false;
}

// This is a fire and forget.
export async function GetStarted(getStartedBody: any) {
  await fetch(`${apiHostname}/api/GetStarted`, {
    method: "POST",
    body: JSON.stringify(getStartedBody),
  });
}
