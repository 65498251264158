import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface AgreementProps {
  agreementAccepted: boolean;
  setAgreementAccepted: (checked: boolean) => void;
}

function Agreement(props: AgreementProps) {
  const { agreementAccepted, setAgreementAccepted } = props;

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      // getAppInsights().trackEvent({ name: 'Add pro service.', properties: {} });
    } else {
      // getAppInsights().trackEvent({ name: 'Remove pro service.', properties: {} });
    }
    setAgreementAccepted(e.target.checked);
  };

  return (
    <Checkbox checked={agreementAccepted} onChange={onChange}>
      By scheduling an appointment you consent to allow 420ID to contact you via email and text.
      Cancellation Policy; you may cancel at anytime and receive a full and complete refund until
      the time in which you have received your signed Physician Certification Form. Once this form
      is issued and you have received the signed doctors approval you may not receive a refund.
      By checking this box you agree to these terms as well as our
      {' '}
      <a target="_blank" rel="noopener noreferrer" href="https://www.jellyfish.md/terms-conditions/">terms and conditions.</a>
    </Checkbox>
  );
}

export default Agreement;
