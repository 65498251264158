import React from 'react';
import { Button, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
// import { getAppInsights } from './TelemetryService';

const { Text } = Typography;

export interface UploadSelfieProps {
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void,
}

function UploadSelfie(props: UploadSelfieProps) {
  const { selfieFile, setSelfieFile } = props;

  const beforeUpload = (file: RcFile) => {
    setSelfieFile(file);
    // getAppInsights().trackEvent({ name: 'Selfie Chosen' });
    return false;
  };

  return (
    <>
      <Text strong style={{ color: 'red' }}>Upload Selfie Photo: </Text>
      <Text>Please provide an clear photo of your face.</Text>
      <br />
      <Upload id="uploadSelfie" showUploadList={false} beforeUpload={beforeUpload}>
        <Button>
          <UploadOutlined />
          Click to Upload
        </Button>
      </Upload>
      {' '}
      {selfieFile && <CheckOutlined style={{ fontSize: 16, color: 'green' }} />}
    </>
  );
}

export default UploadSelfie;
