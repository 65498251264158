import React from 'react';
import { Button, Form } from 'antd';

function NextButton({ disabled = false }: { disabled: boolean }) {
  return (
    <Form.Item wrapperCol={{ style: { alignItems: 'center' } }}>
      <Button type="primary" htmlType="submit" disabled={disabled}>
        Next
      </Button>
    </Form.Item>
  );
}

export default NextButton;
