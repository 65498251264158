import { Card, Col, Row, Steps } from "antd";
import { Store } from "antd/lib/form/interface";
import { RcFile } from "antd/lib/upload";
import currency from "currency.js";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BookAppointment, {
  BookAppointmentParams,
} from "../shared/BookAppointment";
// import { getAppInsights } from '../shared/TelemetryService';
import {
  BillingInformation,
  BookingInformation,
  JurisdictionInfo,
} from "../shared/Types";
import { ChooseAppointmentStep } from "../shared/ChooseAppointment";
import ConfirmationStep from "./Confirmation";
import FinalizeStep from "./Finalize";
import PaymentInformationStep from "./PaymentInformation";
import YourInformationStep from "./YourInformation";

import WordLogo from "../420id.png";
import AppointmentImage from "../appointmentType-thumb.png";
import GetStartedStep from "./GetStarted";
import "./index.css";

function NewAppointment({
  headerText,
  jurisdictionInfo,
  upgradeText,
}: {
  headerText: string;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}) {
  // A custom hook that builds on useLocation to parse the query string for you.
  function useLocationPath() {
    return useLocation().pathname;
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const defaultBookingInfo: BookingInformation = {
    name: {
      first: query.get("first_name") || "",
      middle: "",
      last: query.get("last_name") || "",
    },
    email: query.get("email") || "",
    address: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    },
    phone: "",
    county: "",
    jurisdiction: jurisdictionInfo.abbr,
  };

  const defaultBillingInformation: BillingInformation = {
    cardNumber: "",
    billingName: "",
    expiryMonth: "",
    expiryYear: "",
    cvc: "",
    certificate: query.get("certificate") || "",
    howDidYouHear: "",
  };

  const [qualifyingCondition, setQualifyingCondition] = useState("");
  const [current, setCurrent] = useState(0);
  const [appointment, setAppointment] = useState(new Date(0));
  const [paymentError, setPaymentError] = useState(false);
  // eslint-disable-next-line max-len
  const [bookingInformation, setBookingInformation] =
    useState<BookingInformation>(defaultBookingInfo);
  // eslint-disable-next-line max-len
  const [billingInformation, setBillingInformation] =
    useState<BillingInformation>(defaultBillingInformation);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  // const [recordsFile, setRecordsFile] = useState<RcFile>();
  const [idFile, setIdFile] = useState<RcFile>();
  const [selfieFile, setSelfieFile] = useState<RcFile>();
  const [proAlternativeDocFile, setProAlternativeDocFile] = useState<RcFile>();

  const paymentFormFinish = (values: Store, discount: currency) => {
    const billInfo: BillingInformation = {
      cardNumber: values.number.replace(/ /g, ""),
      billingName: values.billing_name,
      expiryMonth: values.expiryMonth,
      expiryYear: values.expiryYear,
      cvc: values.cvc,
      certificate: values.certificate,
      discountAmount: discount,
      howDidYouHear: values.howDidYouHear,
    };
    setBillingInformation(billInfo);
    // getAppInsights().trackEvent({ name: 'Submitted Payment Information' });
    setPaymentError(false);
    setCurrent(4);
  };

  const isEditable = !isSubmitting && !submitted;

  const appointmentChosen = (selectedAppointment: Date) => {
    setAppointment(selectedAppointment);
    setCurrent(2);
  };

  const locationPath = useLocationPath();

  const finalizeAppointmentClicked = (
    proService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => {
    let source = "";
    if (locationPath === "/mogc") {
      source = "mogc";
    }
    const bookAppointmentParams: BookAppointmentParams = {
      setCurrent,
      appointment,
      billingInformation,
      setIsSubmitting,
      setPaymentError,
      setSubmitted,
      // recordsFile,
      idFile,
      selfieFile,
      proAlternativeDocFile,
      bookingInformation,
      proService,
      proAlternativeService,
      sixteenOzService,
      jurisdictionInfo,
      source,
    };

    BookAppointment(bookAppointmentParams);
  };

  return (
    <Row>
      <Col xl={{ offset: 6, span: 12 }}>
        <Card>
          <Row>
            <Col style={{ textAlign: "center", margin: "auto" }}>
              <img src={WordLogo} alt="Logo" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 4 }}>
              <img
                src={AppointmentImage}
                alt=""
                id="doctor-image"
                style={{ width: "150px" }}
              />
            </Col>
            <Col xl={{ span: 20 }}>
              <div style={{ paddingTop: "25px" }}>{headerText}</div>
            </Col>
          </Row>
        </Card>
        <Card className="bodyWrapper">
          <Steps direction="vertical" current={current} className="steps">
            <GetStartedStep
              setCurrent={setCurrent}
              isSubmitting={isSubmitting}
              submitted={submitted}
              qualifyingCondition={qualifyingCondition}
              setQualifyingCondition={setQualifyingCondition}
              bookingInformation={bookingInformation}
              setBookingInformation={setBookingInformation}
              jurisdictionInfo={jurisdictionInfo}
            />
            <ChooseAppointmentStep
              setCurrent={setCurrent}
              appointmentChosen={appointmentChosen}
              appointment={appointment}
              isEditable={isEditable}
              jurisdictionInfo={jurisdictionInfo}
            />
            <YourInformationStep
              setCurrent={setCurrent}
              isSubmitting={isSubmitting}
              submitted={submitted}
              bookingInformation={bookingInformation}
              setBookingInformation={setBookingInformation}
              jurisdictionInfo={jurisdictionInfo}
            />
            <PaymentInformationStep
              setCurrent={setCurrent}
              isSubmitting={isSubmitting}
              submitted={submitted}
              paymentError={paymentError}
              setPaymentError={setPaymentError}
              billingInformation={billingInformation}
              setBillingInformation={setBillingInformation}
              paymentFormFinish={paymentFormFinish}
              jurisdictionInfo={jurisdictionInfo}
            />
            <FinalizeStep
              isSubmitting={isSubmitting}
              billingInformation={billingInformation}
              idFile={idFile}
              setIdFile={setIdFile}
              selfieFile={selfieFile}
              setSelfieFile={setSelfieFile}
              proAlternativeDocFile={proAlternativeDocFile}
              setProAlternativeDocFile={setProAlternativeDocFile}
              finalizeClicked={finalizeAppointmentClicked}
              jurisdictionInfo={jurisdictionInfo}
              upgradeText={upgradeText}
            />
            <ConfirmationStep
              appointment={appointment}
              bookingInformation={bookingInformation}
              jurisdictionInfo={jurisdictionInfo}
            />
          </Steps>
        </Card>
      </Col>
    </Row>
  );
}

export default NewAppointment;
