import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import React from "react";
import { ChooseAppointment, ChooseAppointmentProps } from "./Main";
import { ChooseAppointmentTitle, ChooseAppointmentTitleProps } from "./Title";

import { JurisdictionInfo } from "../Types";
import "./ChooseAppointment.css";

const { Step } = Steps;

export interface ChooseAppointmentStepProps {
  setCurrent: (cur: number) => void;
  appointmentChosen: (appointment: Date) => void;
  appointment: Date;
  isEditable: boolean;
  jurisdictionInfo: JurisdictionInfo;
}

export function ChooseAppointmentStep(
  props: ChooseAppointmentStepProps & StepProps
) {
  const {
    setCurrent,
    appointmentChosen,
    appointment,
    isEditable,
    jurisdictionInfo,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const chooseAppointmentTitleProps: ChooseAppointmentTitleProps = {
    isChangeable: isEditable,
    status,
    onClick: () => {
      setCurrent(1);
    },
  };

  const chooseAppointmentProps: ChooseAppointmentProps = {
    appointment,
    status,
    timeSelected: appointmentChosen,
    jurisdictionInfo,
  };

  return (
    <Step
      key="Choose Appointment"
      title={ChooseAppointmentTitle(chooseAppointmentTitleProps)}
      description={ChooseAppointment(chooseAppointmentProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}
