import React, { useEffect, useRef } from 'react';

export interface GetStartedTitleProps {
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}

export function GetStartedTitle(props: GetStartedTitleProps) {
  const { status } = props;
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (status === 'process') {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [status]);

  return (
    <span ref={ref} style={{ float: 'left' }}>
      <div className="ant-steps-item-icon">
        <span className="ant-steps-icon">1</span>
      </div>
      Get Started
    </span>
  );
}
