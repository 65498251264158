import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import React from "react";
import { JurisdictionInfo } from "../../shared/Types";
import { SelectOption, SelectOptionProps } from "./Main";
import { SelectOptionTitle } from "./Title";

const { Step } = Steps;

export interface SelectOptionStepProps {
  upgradeOption: "patient" | "cultivation" | undefined;
  proAlternative: boolean;
  onFinish: (values: {
    upgradeOption: "patient" | "cultivation";
    proAlternative: boolean;
    upgradePatient: boolean;
    upgradeCultivation: boolean;
    upgradeSixteenOz: boolean;
  }) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}

function SelectOptionStep(props: SelectOptionStepProps & StepProps) {
  const {
    upgradeOption,
    proAlternative,
    onFinish,
    jurisdictionInfo,
    upgradeText,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const selectOptionProps: SelectOptionProps = {
    upgradeOption: jurisdictionInfo.proCultivationUpgradeAvailable
      ? upgradeOption
      : "patient",
    proAlternative,
    status,
    onFinish,
    jurisdictionInfo,
    upgradeText,
  };

  return (
    <Step
      key="SelectOption"
      title={SelectOptionTitle()}
      description={SelectOption(selectOptionProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default SelectOptionStep;
