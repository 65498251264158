import React from 'react';
import { Button, Form } from 'antd';

function NextButton() {
  return (
    <Form.Item wrapperCol={{ style: { alignItems: 'center' } }}>
      <Button type="primary" htmlType="submit">
        Next
      </Button>
    </Form.Item>
  );
}

export default NextButton;
