import { Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { ProAlternativeInfo } from '../../shared/Types';
import UploadDocument from '../../shared/UploadDocument';

const { Text } = Typography;

export interface ProAlternativeProps {
  proAlternative: boolean;
  setProAlternative: React.Dispatch<React.SetStateAction<boolean>>;
  proAlternativeInfo: ProAlternativeInfo;
  docFile: RcFile | undefined;
  setDocFile: (file: RcFile) => void;
}

const eventName = 'Upload Supporting Pro Alternative Document';

export function ProAlternative(props: ProAlternativeProps) {
  const {
    proAlternative, setProAlternative, proAlternativeInfo, docFile, setDocFile,
  } = props;

  const proAlternativeCheckboxOnChange = (e: CheckboxChangeEvent) => {
    setProAlternative(e.target.checked);
  };

  return (
    <div>
      <Checkbox checked={proAlternative} onChange={proAlternativeCheckboxOnChange}>
        <Text strong>{proAlternativeInfo.prompt}</Text>
      </Checkbox>
      <br />
      {proAlternative && (
      <UploadDocument
        docFile={docFile}
        setDocFile={setDocFile}
        headerText={proAlternativeInfo.supportingDocHeader}
        docDescriptions={proAlternativeInfo.supportingDocDescription}
        eventName={eventName}
      />
      )}
    </div>
  );
}
