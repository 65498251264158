import { Button, Card, Checkbox, Form, Radio, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import { JurisdictionInfo } from "../../shared/Types";

const { Text } = Typography;

export interface SelectOptionProps {
  // eslint-disable-next-line react/require-default-props
  upgradeOption?: "patient" | "cultivation";
  // eslint-disable-next-line react/require-default-props
  proAlternative?: boolean;
  // eslint-disable-next-line react/require-default-props
  sixteenOz?: boolean;
  status?: "wait" | "process" | "finish" | "error";
  onFinish: (values: {
    upgradeOption: "patient" | "cultivation";
    proAlternative: boolean;
    upgradePatient: boolean;
    upgradeCultivation: boolean;
    upgradeSixteenOz: boolean;
  }) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}

export function SelectOption(props: SelectOptionProps) {
  const {
    upgradeOption,
    proAlternative,
    sixteenOz,
    status,
    onFinish,
    jurisdictionInfo,
    upgradeText,
  } = props;
  const [proPrice, setProPrice] = useState<number>(jurisdictionInfo.proPrice);
  const [proCultivationPrice, setProCultivationPrice] = useState<number>(
    jurisdictionInfo.proPrice + jurisdictionInfo.proCultivationPrice
  );

  const [upgradePatient, setUpgradePatient] = useState<boolean>(false);
  const [upgradeCultivation, setUpgradeCultivation] = useState<boolean>(false);
  const [upgradeSixteenOz, setUpgradeSixteenOz] = useState<boolean>(false);
  const [upgradeProAlternative, setUpgradeProAlternative] =
    useState<boolean>(false);

  const proAlternativeChanged = (e: CheckboxChangeEvent) => {
    setUpgradeProAlternative(e.target.checked);
    setProPrice(
      e.target.checked
        ? jurisdictionInfo.proAlternativeInfo!.price
        : jurisdictionInfo.proPrice
    );
    setProCultivationPrice(
      (e.target.checked
        ? jurisdictionInfo.proAlternativeInfo!.price
        : jurisdictionInfo.proPrice) + jurisdictionInfo.proCultivationPrice
    );
  };

  const upgradePatientChanged = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setUpgradePatient(true);
      setUpgradeCultivation(false);
    } else {
      setUpgradePatient(false);
    }
  };

  const upgradeCultivationChanged = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setUpgradePatient(false);
      setUpgradeCultivation(true);
    } else {
      setUpgradeCultivation(false);
    }
  };

  const upgradeSixteenOzChanged = (e: CheckboxChangeEvent) => {
    setUpgradeSixteenOz(e.target.checked);
  };

  if (status === "process") {
    return (
      <>
        <Card style={{ marginBottom: "15px" }}>
          <div style={{ float: "left" }}>
            <Text strong>Upgrade Options</Text>
            <br />
            {upgradeText &&
              upgradeText.map((text, i) => (
                <>
                  <Text>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                  </Text>
                  {upgradeText[i + 1] && <br />}
                </>
              ))}
          </div>
        </Card>
        <div>
          <Form
            name="upgrade"
            onFinish={onFinish}
            initialValues={{
              upgradeOption,
              proAlternative,
              sixteenOz,
              upgradePatient,
              upgradeCultivation,
            }}
          >
            {jurisdictionInfo.proAlternativeInfo && (
              <>
                <Form.Item name="proAlternative" valuePropName="value">
                  <Checkbox
                    checked={upgradeProAlternative}
                    onChange={proAlternativeChanged}
                  >
                    <Text strong>
                      {jurisdictionInfo.proAlternativeInfo.prompt}
                    </Text>
                  </Checkbox>
                </Form.Item>
                <br />
              </>
            )}
            {!jurisdictionInfo.sixteenOzUpgradeAvailable && (
              <Form.Item
                name="upgradeOption"
                rules={[
                  {
                    required: true,
                    message: "Please choose an upgrade option.",
                  },
                ]}
                hidden={!jurisdictionInfo.proCultivationUpgradeAvailable}
              >
                <Radio.Group onChange={(e => {
                  if(e.target.value === "patient") {
                    setUpgradePatient(true);
                    setUpgradeCultivation(false);
                  } else {
                    setUpgradePatient(false);
                    setUpgradeCultivation(true);
                  }
                })}>
                  <Radio value="patient">
                    Patient Application - ${proPrice}
                  </Radio>
                  <br />
                  <Radio value="cultivation">
                    Patient + Cultivation - ${proCultivationPrice}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {jurisdictionInfo.sixteenOzUpgradeAvailable && (
              <>
                <Form.Item name="upgradePatient" valuePropName="value">
                  <Checkbox
                    checked={upgradePatient}
                    onChange={upgradePatientChanged}
                  >
                    Patient Application - ${proPrice}
                  </Checkbox>
                </Form.Item>
                <Form.Item name="upgradeCultivation" valuePropName="value">
                  <Checkbox
                    checked={upgradeCultivation}
                    onChange={upgradeCultivationChanged}
                  >
                    Patient + Cultivation - ${proCultivationPrice}
                  </Checkbox>
                </Form.Item>
                <br />
                <Form.Item name="upgradeSixteenOz" valuePropName="value">
                  <Checkbox
                    checked={upgradeSixteenOz}
                    onChange={upgradeSixteenOzChanged}
                  >
                    16oz possession - ${jurisdictionInfo.sixteenOzUpgradePrice}
                  </Checkbox>
                </Form.Item>
              </>
            )}
            {!jurisdictionInfo.proCultivationUpgradeAvailable && (
              <>
                Patient Application - ${proPrice}
                <br />
              </>
            )}
            <br />
            <Button
              type="primary"
              onClick={() =>
                onFinish({
                  upgradePatient,
                  upgradeCultivation,
                  upgradeSixteenOz,
                  upgradeOption: upgradeOption!,
                  proAlternative: upgradeProAlternative,
                })
              }
            >
              Next
            </Button>
          </Form>
        </div>
      </>
    );
  }

  if (status === "finish") {
    return <Text strong>{upgradeOption}</Text>;
  }

  return null;
}
