import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import { Button, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
// import { getAppInsights } from './TelemetryService';

const { Text } = Typography;

export interface UploadSupportingDocumentProps {
  headerText: string;
  docDescriptions: string[];
  eventName: string;
  docFile: RcFile | undefined;
  setDocFile: (file: RcFile) => void;
}

function UploadDocument(props: UploadSupportingDocumentProps) {
  const {
    // headerText, docDescriptions, eventName, docFile, setDocFile,
    headerText, docDescriptions, docFile, setDocFile,
  } = props;

  const beforeUpload = (file: RcFile) => {
    setDocFile(file);
    // getAppInsights().trackEvent({ name: eventName });
    return false;
  };

  return (
    <>
      <br />
      <Text key="UploadDocumentHeader" strong style={{ color: 'red' }}>
        {headerText}
      </Text>
      <br />
      {docDescriptions.map((description, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`UploadDocumentDescription${index}`}>
          <Text>{description}</Text>
          <br />
        </div>
      ))}
      <br />
      <Upload id="uploadDocument" showUploadList={false} beforeUpload={beforeUpload}>
        <Button>
          <UploadOutlined />
          Click to Upload
        </Button>
      </Upload>
      {' '}
      {docFile && <CheckOutlined style={{ fontSize: 16, color: 'green' }} />}
    </>
  );
}

export default UploadDocument;
