import { Steps } from 'antd';
import { StepProps } from 'antd/lib/steps';
import React from 'react';
import { BookingInformation, JurisdictionInfo } from '../../shared/Types';
import { GetStarted, GetStartedProps } from './Main';
import { GetStartedTitle, GetStartedTitleProps } from './Title';

const { Step } = Steps;

export interface GetStartedStepProps {
  setCurrent: (current: number) => void;
  isSubmitting: boolean;
  submitted: boolean;
  bookingInformation: BookingInformation;
  setBookingInformation: (bookingInfo: BookingInformation) => void;
  qualifyingCondition: string;
  setQualifyingCondition: (condition: string) => void;
  jurisdictionInfo: JurisdictionInfo;
}

function GetStartedStep(props: GetStartedStepProps & StepProps) {
  const {
    setCurrent,
    isSubmitting,
    submitted,
    bookingInformation,
    setBookingInformation,
    qualifyingCondition,
    setQualifyingCondition,
    jurisdictionInfo,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const getStartedTitleProps: GetStartedTitleProps = {
    status,
  };

  const getStartedProps: GetStartedProps = {
    setCurrent,
    bookingInformation,
    setBookingInformation,
    status,
    qualifyingCondition,
    setQualifyingCondition,
    jurisdictionInfo,
  };

  return (
    <Step
      key="Get Started"
      title={GetStartedTitle(getStartedTitleProps)}
      description={GetStarted(getStartedProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default GetStartedStep;
