import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import { RcFile } from "antd/lib/upload";
import React from "react";
import { JurisdictionInfo } from "../../shared/Types";
import { UploadImages, UploadImagesProps } from "./Main";
import { UploadImagesTitle } from "./Title";

const { Step } = Steps;

export interface UploadImagesStepProps {
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  promptProAlternativeDoc: boolean;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  nextClicked: () => void;
  jurisdictionInfo: JurisdictionInfo;
}

function UploadImagesStep(props: UploadImagesStepProps & StepProps) {
  const {
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    promptProAlternativeDoc,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    nextClicked,
    jurisdictionInfo,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const finalizeProps: UploadImagesProps = {
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    promptProAlternativeDoc,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    status,
    nextClicked,
    jurisdictionInfo,
  };

  return (
    <Step
      key="Finalize"
      title={UploadImagesTitle()}
      description={UploadImages(finalizeProps)}
      disabled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default UploadImagesStep;
