import { Checkbox, Col, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

export interface AgeCheckProps {
  isEighteen: boolean;
  setIsEighteen: (checked: boolean) => void;
}

function AgeCheck(props: AgeCheckProps) {
  const { isEighteen, setIsEighteen } = props;

  const onChange = (e: CheckboxChangeEvent) => {
    setIsEighteen(e.target.checked);
  };

  return (
    <Col sm={{ offset: 5 }}>
      <Form.Item
        name="eighteen"
        rules={[
          () => ({
            validator() {
              if (isEighteen) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('You must be 18 to qualify.'));
            },
          }),
        ]}
      >
        <Checkbox checked={isEighteen} onChange={onChange}>
          Yes, I&apos;m 18 or older
        </Checkbox>
      </Form.Item>
    </Col>
  );
}

export default AgeCheck;
