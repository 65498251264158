const LouisianaCounties = [
  'Acadia Parish',
  'Allen Parish',
  'Ascension Parish',
  'Assumption Parish',
  'Avoyelles Parish',
  'Beauregard Parish',
  'Bienville Parish',
  'Bossier Parish',
  'Caddo Parish',
  'Calcasieu Parish',
  'Caldwell Parish',
  'Cameron Parish',
  'Catahoula Parish',
  'Claiborne Parish',
  'Concordia Parish',
  'De Soto Parish',
  'East Baton Rouge Parish',
  'East Carroll Parish',
  'East Feliciana Parish',
  'Evangeline Parish',
  'Franklin Parish',
  'Grant Parish',
  'Iberia Parish',
  'Iberville Parish',
  'Jackson Parish',
  'Jefferson Parish',
  'Jefferson Davis Parish',
  'Lafayette Parish',
  'Lafourche Parish',
  'LaSalle Parish',
  'Lincoln Parish',
  'Livingston Parish',
  'Madison Parish',
  'Morehouse Parish',
  'Natchitoches Parish',
  'Orleans Parish',
  'Ouachita Parish',
  'Plaquemines Parish',
  'Pointe Coupee Parish',
  'Rapides Parish',
  'Red River Parish',
  'Richland Parish',
  'Sabine Parish',
  'St. Bernard Parish',
  'St. Charles Parish',
  'St. Helena Parish',
  'St. James Parish',
  'St. John the Baptist Parish',
  'St. Landry Parish',
  'St. Martin Parish',
  'St. Mary Parish',
  'St. Tammany Parish',
  'Tangipahoa Parish',
  'Tensas Parish',
  'Terrebonne Parish',
  'Union Parish',
  'Vermilion Parish',
  'Vernon Parish',
  'Washington Parish',
  'Webster Parish',
  'West Baton Rouge Parish',
  'West Carroll Parish',
  'West Feliciana Parish',
  'Winn Parish',
];

export default LouisianaCounties;
