import currency from "currency.js";
import { Questions } from "../Renewal/Questionnaire/Main";

export interface ScheduleAppointmentDetails {
  appointmentDate: Date;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  county: string;
  certificate: string;
  number: string;
  billingName: string;
  expiry: string;
  cvc: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  proService: boolean;
  proAlternativeService: boolean;
  sixteenOzService: boolean;
  source?: string;
  howDidYouHear: string;
  jurisdiction: string;
}

export interface RenewalDetails {
  token: string;
  confirmEmail: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  county: string;
  certificate: string;
  number: string;
  billingName: string;
  expiry: string;
  cvc: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  questions: Questions;
  proService: boolean;
  proCultivationService: boolean;
  proAlternativeService: boolean;
  sixteenOzService: boolean;
  jurisdiction: string;
  renewalType: RenewalType;
  appointmentDate?: Date;
}

export interface BookingInformation {
  name: {
    first: string;
    middle: string;
    last: string;
  };
  email: string;
  address: {
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
  };
  phone: string;
  county: string;
  jurisdiction: string;
}

export interface BillingInformation {
  cardNumber: string;
  billingName: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  certificate: string;
  discountAmount?: currency;
  howDidYouHear: string;
}

export interface UpgradeBody {
  appointmentId: string;
  token: string;
  number: string;
  billingName: string;
  expiry: string;
  cvc: string;
  proUpgradeOption?: "patient" | "cultivation";
  proAlternative: boolean;
  upgradeSixteenOz: boolean;
  jurisdiction: string;
}

export interface ProAlternativeInfo {
  price: number;
  prompt: string;
  supportingDocHeader: string;
  supportingDocDescription: string[];
}

export interface JurisdictionInfo {
  abbr: string;
  name: string;
  reviewUrl: string;
  applicationFee: number;
  reviewPrice: number;
  proUpgradeAvailable: boolean;
  proPrice: number;
  proCultivationUpgradeAvailable: boolean;
  proCultivationPrice: number;
  sixteenOzUpgradeAvailable: boolean;
  sixteenOzUpgradePrice: number;
  renewalPrice: number;
  returnPrice: number;
  proAlternativeInfo?: ProAlternativeInfo;
  counties: string[];
}

export enum RenewalType {
  Standard,
  RapidReturn,
}
