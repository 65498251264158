import React from 'react';
import { Button, Form } from 'antd';

export interface NextButtonProps {
  setFocus: Function;
}

const wrapperCol = {
  xs: {
    span: 24,
    offset: 0,
  },
  sm: {
    span: 16,
    offset: 8,
  },
  style: {
    alignItems: 'center',
  },
};

function NextButton(props: NextButtonProps) {
  const { setFocus } = props;

  return (
    <Form.Item wrapperCol={wrapperCol}>
      <Button type="primary" htmlType="submit" onFocus={() => setFocus(undefined)}>
        Next
      </Button>
    </Form.Item>
  );
}

export default NextButton;
