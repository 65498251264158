import { Card, Form, Typography } from "antd";
import { Store } from "antd/lib/form/interface";
import { useEffect, useState } from "react";
import {
  CheckRenewalToken,
  GetStartedRapidReturn,
} from "../../shared/ApiWrapper";
import { JurisdictionInfo, RenewalType } from "../../shared/Types";
import EmailInput from "./Email";
import NextButton from "./NextButton";

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface ConfirmEmailProps {
  status: "wait" | "process" | "finish" | "error" | undefined;
  token: string;
  email: string;
  renewalType: RenewalType;
  jurisdictionInfo: JurisdictionInfo;
  onFinish(email: string): void;
}

export function ConfirmEmail(props: ConfirmEmailProps) {
  const {
    status,
    onFinish,
    email: confirmEmail,
    token,
    renewalType,
    jurisdictionInfo,
  } = props;
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  useEffect(() => {
    const load = async () => {
      await GetStartedRapidReturn(token, jurisdictionInfo.abbr);
    };
    load();
  }, [token, jurisdictionInfo]);

  const finishFunc = async (values: Store) => {
    const { email } = values;
    setShowErrorMessage(false);

    const valid =
      RenewalType.Standard === renewalType
        ? await CheckRenewalToken(token, email)
        : await GetStartedRapidReturn(token, jurisdictionInfo.abbr, email);
    if (valid === true) {
      onFinish(email);
    } else {
      setShowErrorMessage(true);
    }
  };

  if (status === "process") {
    return (
      <>
        <Card style={{ marginBottom: "15px" }}>
          <div style={{ float: "left" }}>
            <Text strong>Confirm your email address.</Text>
            <br />
            <Text>
              The link we sent you was made specially for you! To continue, we
              just need to make sure that we have the right person.
            </Text>
          </div>
        </Card>
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          name="register"
          onFinish={finishFunc}
          scrollToFirstError
        >
          <EmailInput />
          <Form.Item hidden={!showErrorMessage}>
            <Text>
              You must provide the same email address that was on your original
              appointment to submit a renewal request.
            </Text>
          </Form.Item>
          <NextButton />
        </Form>
      </>
    );
  }

  return `${confirmEmail}`;
}
