import { Button, Card, Checkbox, Col, Row, Spin, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { RcFile } from "antd/lib/upload";
import currency from "currency.js";
import React, { useState } from "react";
// import { getAppInsights } from '../../shared/TelemetryService';
import { BillingInformation, JurisdictionInfo } from "../../shared/Types";
import UploadId from "../../shared/UploadId";
import UploadSelfie from "../../shared/UploadSelfie";
import Agreement from "./Agreement";
import { ProAlternative } from "./ProAlternative";

const { Text } = Typography;

export interface FinalizeProps {
  isSubmitting: boolean;
  // recordsFile: RcFile | undefined;
  billingInformation: BillingInformation | undefined;
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  status: "wait" | "process" | "finish" | "error" | undefined;
  finalizeClicked: (
    proService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}

export function Finalize(props: FinalizeProps) {
  const {
    isSubmitting,
    billingInformation,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    status,
    finalizeClicked,
    jurisdictionInfo,
    upgradeText,
  } = props;
  const [proService, setProService] = useState<boolean>(false);
  const [proAlternativeService, setProAlternativeService] =
    useState<boolean>(false);
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
  const [sixteenOzService, setSixteenOzService] = useState<boolean>(false);

  const discount = billingInformation?.discountAmount;
  const proPrice = proAlternativeService
    ? jurisdictionInfo.proAlternativeInfo?.price
    : jurisdictionInfo.proPrice;
  const price = currency(jurisdictionInfo.reviewPrice)
    .subtract(discount || 0)
    .add(proService ? proPrice! : 0)
    .add(sixteenOzService ? jurisdictionInfo.sixteenOzUpgradePrice : 0);

  const imagesSelected = idFile !== undefined && selfieFile !== undefined;
  const buttonDisabled =
    isSubmitting || !agreementAccepted || (proService && !imagesSelected);
  const paymentMessage =
    "We will charge you $50 today and the balance after the doctor qualifies you. If we can't qualify you, you will get your $50 refunded.";
  const upgradeProServiceCheckboxOnChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      // getAppInsights().trackEvent({ name: 'Add pro service.', properties: {} });
    } else {
      // getAppInsights().trackEvent({ name: 'Remove pro service.', properties: {} });
    }
    setProService(e.target.checked);
  };
  const upgradeSixteenOzCheckboxOnChange = (e: CheckboxChangeEvent) => {
    setSixteenOzService(e.target.checked);
  };

  if (status === "process") {
    return (
      <>
        {jurisdictionInfo.proUpgradeAvailable && (
          <Card style={{ marginBottom: "15px" }}>
            <div style={{ float: "left" }}>
              <Text strong>UPGRADE OPTIONS</Text>
              <br />
              {upgradeText &&
                upgradeText.map((text, i) => (
                  <>
                    <Text>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Text>
                    {upgradeText[i + 1] && <br />}
                  </>
                ))}
            </div>
          </Card>
        )}
        {!jurisdictionInfo.proUpgradeAvailable && (
          <Card style={{ marginBottom: "15px" }}>
            <div style={{ float: "left" }}>
              <Text strong>Last step!</Text>
              <br />
              <Text>
                You just need to accept the terms below and you're all set!
              </Text>
            </div>
          </Card>
        )}
        <div>
          {jurisdictionInfo.proUpgradeAvailable && (
            <>
              <Checkbox
                checked={proService}
                onChange={upgradeProServiceCheckboxOnChange}
              >
                <Text strong>
                  Yes! Upgrade me to <i>Pro Service</i> for $
                  {jurisdictionInfo.proPrice} more!
                </Text>
              </Checkbox>
              <br />
            </>
          )}
          {proService && (
            <>
              <br />
              <Text strong>Great! To do that, we need a couple things:</Text>
              <br />
              <br />
              <UploadSelfie
                selfieFile={selfieFile}
                setSelfieFile={setSelfieFile}
              />
              <br />
              <br />
              <UploadId
                idFile={idFile}
                setIdFile={setIdFile}
                jurisdictionInfo={jurisdictionInfo}
              />
              <br />
              {jurisdictionInfo.proAlternativeInfo && (
                <>
                  <br />
                  <ProAlternative
                    proAlternative={proAlternativeService}
                    setProAlternative={setProAlternativeService}
                    docFile={proAlternativeDocFile}
                    setDocFile={setProAlternativeDocFile}
                    proAlternativeInfo={jurisdictionInfo.proAlternativeInfo}
                  />
                </>
              )}
            </>
          )}
          <br />
          {jurisdictionInfo.sixteenOzUpgradeAvailable && (
            <>
              <Checkbox
                checked={sixteenOzService}
                onChange={upgradeSixteenOzCheckboxOnChange}
              >
                <Text strong>
                  {`Yes! I would like a second physician's certification so I can possess up to 16oz for $${jurisdictionInfo.sixteenOzUpgradePrice} more!`}
                </Text>
              </Checkbox>
              <br />
              <br />
            </>
          )}
          {jurisdictionInfo.name} Review:{" "}
          {currency(jurisdictionInfo.reviewPrice).format()}
          <br />
          {billingInformation?.certificate &&
            billingInformation?.certificate !== "" && (
              <>
                {`Promo Code: ${billingInformation?.certificate}`}
                <br />
              </>
            )}
          {billingInformation?.discountAmount &&
            billingInformation?.discountAmount.value > 0 && (
              <>
                {`Discount: -${currency(
                  billingInformation?.discountAmount
                ).format()}`}
                <br />
              </>
            )}
          {proService && (
            <>
              {`Pro Service Addon: ${currency(
                (proAlternativeService
                  ? jurisdictionInfo.proAlternativeInfo?.price
                  : jurisdictionInfo.proPrice)!
              ).format()}`}
              <br />
            </>
          )}
          {sixteenOzService && (
            <>
              {`16oz Upgrade: ${currency(
                jurisdictionInfo.sixteenOzUpgradePrice
              ).format()}`}
              <br />
            </>
          )}
          {((billingInformation?.discountAmount &&
            billingInformation?.discountAmount.value > 0) ||
            proService) && (
            <>
              Total: {price.format()}
              <br />
            </>
          )}
          <br />
          <Row align="middle">
            <Col>
              <Text>{paymentMessage}</Text>
              <br />
              <br />
              <Agreement
                agreementAccepted={agreementAccepted}
                setAgreementAccepted={setAgreementAccepted}
              />
              <br />
              <br />
              <Button
                disabled={buttonDisabled}
                type="primary"
                onClick={() => {
                  finalizeClicked(
                    proService,
                    proAlternativeService,
                    sixteenOzService
                  );
                }}
              >
                Finalize
              </Button>
            </Col>
            {isSubmitting && (
              <Col>
                <Spin style={{ margin: "5px 0px 0px 5px" }} />
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }

  if (status === "finish") {
    return <Text strong>Done!</Text>;
  }

  return null;
}
