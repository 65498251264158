import { RcFile } from "antd/lib/upload";
import { OrderUpgrade } from "./ApiWrapper";
import { uploadId, uploadSelfie, uploadproAlternativeDoc } from "./ImageUtils";
// import { getAppInsights } from './TelemetryService';
import { BillingInformation, JurisdictionInfo, UpgradeBody } from "./Types";

export interface BookUpgradeParams {
  proUpgradeOption?: "patient" | "cultivation";
  proAlternative: boolean;
  upgradeSixteenOz: boolean;
  appointmentId: string;
  setCurrent: Function;
  setIsSubmitting: (isSubmitting: boolean) => void;
  token: string;
  idFile?: RcFile;
  selfieFile?: RcFile;
  proAlternativeDocFile: RcFile | undefined;
  billingInformation: BillingInformation | undefined;
  setPaymentError: Function;
  setSubmitted: (submitted: boolean) => void;
  jurisdictionInfo: JurisdictionInfo;
}

export async function BookUpgrade(params: BookUpgradeParams) {
  const {
    token,
    proUpgradeOption,
    proAlternative,
    upgradeSixteenOz,
    appointmentId,
    setIsSubmitting,
    billingInformation,
    setSubmitted,
    idFile,
    selfieFile,
    proAlternativeDocFile,
    jurisdictionInfo,
  } = params;
  try {
    // getAppInsights().trackEvent({ name: 'Booking Upgrade Started' });
    setIsSubmitting(true);
    const { billingName } = billingInformation!;
    const number = billingInformation!.cardNumber;
    const expiry =
      billingInformation!.expiryMonth +
      billingInformation!.expiryYear.substring(2, 4);
    const { cvc } = billingInformation!;

    const upgradeBody: UpgradeBody = {
      token,
      number,
      billingName,
      expiry,
      cvc,
      proUpgradeOption: proUpgradeOption,
      proAlternative,
      upgradeSixteenOz,
      appointmentId,
      jurisdiction: jurisdictionInfo.abbr,
    };

    const result = await OrderUpgrade(upgradeBody);
    if (idFile) {
      uploadId(appointmentId, result.key, idFile);
    }
    if (selfieFile) {
      uploadSelfie(appointmentId, result.key, selfieFile);
    }
    if (proAlternativeDocFile) {
      uploadproAlternativeDoc(appointmentId, result.key, proAlternativeDocFile);
    }

    setSubmitted(true);
    // getAppInsights().trackEvent({ name: 'Booking Upgrade Succeeded' });

    params.setCurrent(4);
  } catch (error) {
    params.setPaymentError(true);
    // getAppInsights().trackEvent({ name: 'Booking Upgrade Failed' });
    params.setCurrent(3);
  } finally {
    setIsSubmitting(false);
  }
}
