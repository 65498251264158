import currency from "currency.js";
import Renewal from "./Renewal";
import { jurisdictionInfo } from "./shared/Constants";
import { RenewalType } from "./shared/Types";

const upgradeText = [
  `<i>Pro Service</i>. This option includes us completing your state application and paying your state application fee (${currency(
    jurisdictionInfo.MO.applicationFee
  ).format()}) on your behalf. This option is designed for anyone who is uncomfortable with computers and technology and would like extra assistance.`,
  `<i>16oz Upgrade</i>. This option includes a second physician certifcation which will allow you to possess up to 16oz.`,
];

function MissouriRapidReturn() {
  const headerText = `<b>The Missouri Medical 420ID Card is better than ever!</b>
    <br />
    <br />
    The Missouri Medical 420ID Card program has more benefits than ever. The passage of amendment 3 introduces exciting, long awaited improvements and protections to 420ID card holders.  There's more reason than ever for those who qualify for the medical program to keep their Medical Advantage.
    <br />
    <br />
    <ul>
    <li>Cards Now Valid for 3 Years</li>
    <li>Bypass the lines with "VIP Dispenary Access"</li>
    <li>Priority Access to Inventory</li>
    <li>Purchase up to 500% more (from 3 oz up to 16 oz)</li>
    <li>Employment & Custody Protections for Medical use</li>
    <li>Up to 5% Tax Savings</li>
    <br />
    Renewal Cost: $${jurisdictionInfo.MO.returnPrice + 55}.
    <br />
    Special Discount: $55
    <br />
    Total Cost: $${jurisdictionInfo.MO.returnPrice}
    <br />
    `;
  return (
    <Renewal
      headerText={headerText}
      smallPrint={""}
      jurisdictionInfo={jurisdictionInfo.MO}
      upgradeText={upgradeText}
      renewalType={RenewalType.RapidReturn}
    />
  );
}

export default MissouriRapidReturn;
