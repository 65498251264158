import { StepProps } from "antd/lib/steps";
import React from "react";
import { Steps } from "antd";
import { ConfirmEmail, ConfirmEmailProps } from "./Main";
import ConfirmEmailTitle from "./Title";
import { JurisdictionInfo, RenewalType } from "../../shared/Types";

const { Step } = Steps;

export interface ConfirmEmailStepProps {
  email: string;
  token: string;
  renewalType: RenewalType;
  jurisdictionInfo: JurisdictionInfo;
  onFinish: (email: string) => void;
}

export function ConfirmEmailStep(props: ConfirmEmailStepProps & StepProps) {
  const {
    onFinish,
    email,
    token,
    renewalType,
    jurisdictionInfo,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const confirmEmailProps: ConfirmEmailProps = {
    status,
    email,
    token,
    renewalType,
    jurisdictionInfo,
    onFinish,
  };

  return (
    <Step
      key="Confirm Email"
      title={ConfirmEmailTitle()}
      description={ConfirmEmail(confirmEmailProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}
