import React from 'react';
import { Form, Input } from 'antd';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

function formatPhoneNumber(value: string) {
  const clearValue = clearNumber(value);

  const areaCode = clearValue.slice(0, 3);
  const prefix = clearValue.slice(3, 6);
  const lineNumber = clearValue.slice(6, 10);

  const firstSegment = clearValue.length > 3 ? `(${areaCode}) ` : areaCode;
  const secondSegment = clearValue.length > 6 ? `${prefix}-` : prefix;
  const thirdSegment = lineNumber;

  return `${firstSegment}${secondSegment}${thirdSegment}`;
}

function PhoneInput() {
  const normalize = (value: any) => formatPhoneNumber(value);

  return (
    <Form.Item
      name="phone"
      label="Phone"
      normalize={normalize}
      rules={[
        { pattern: /\([2-9]\d{2}\) \d{3}-\d{4}/, message: 'Please input your phone number in the (###) ###-#### format.' },
      ]}
    >
      <Input style={{ width: '50%' }} />
    </Form.Item>
  );
}

export default PhoneInput;
