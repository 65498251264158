import {
  Button,
  Card,
  Checkbox,
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RcFile } from "antd/lib/upload";
import currency from "currency.js";
import React, { useState } from "react";
import { ProAlternative } from "../../NewAppointment/Finalize/ProAlternative";
// import { getAppInsights } from '../../shared/TelemetryService';
import {
  BillingInformation,
  JurisdictionInfo,
  RenewalType,
} from "../../shared/Types";
import UploadId from "../../shared/UploadId";
import UploadSelfie from "../../shared/UploadSelfie";
import Agreement from "./Agreement";

const { Text } = Typography;

export interface FinalizeProps {
  isSubmitting: boolean;
  billingInformation: BillingInformation | undefined;
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  status: "wait" | "process" | "finish" | "error" | undefined;
  onFinish: (
    proService: boolean,
    proCultivationService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
  renewalType: RenewalType;
}

export function Finalize(props: FinalizeProps) {
  const {
    isSubmitting,
    billingInformation,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    status,
    onFinish,
    jurisdictionInfo,
    upgradeText,
    renewalType,
  } = props;
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
  const [proService, setProService] = useState<boolean>(false);
  const [proCultivationService, setProCultivationService] =
    useState<boolean>(false);
  const [proAlternativeService, setProAlternativeService] =
    useState<boolean>(false);
  const [sixteenOzService, setSixteenOzUpgrade] = useState<boolean>(false);
  const discount = billingInformation?.discountAmount;
  const renewalPrice = currency(
    RenewalType.Standard === renewalType
      ? jurisdictionInfo.renewalPrice
      : jurisdictionInfo.returnPrice
  ).subtract(discount || 0);

  const onClick = () => {
    onFinish(
      proService,
      proCultivationService,
      proAlternativeService,
      sixteenOzService
    );
  };

  const upgradeProServiceCheckboxOnChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      // getAppInsights().trackEvent({ name: 'Add pro service.', properties: {} });
    } else {
      // getAppInsights().trackEvent({ name: 'Remove pro service.', properties: {} });
    }
    setProService(e.target.checked);
  };

  const upgradePatientChanged = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setProService(true);
      setProCultivationService(false);
    } else {
      setProService(false);
    }
  };

  const upgradeCultivationChanged = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setProService(false);
      setProCultivationService(true);
    } else {
      setProCultivationService(false);
    }
  };

  const upgradeSixteenOzCheckboxOnChange = (e: CheckboxChangeEvent) => {
    setSixteenOzUpgrade(e.target.checked);
  };

  const upgradeRadioOnChange = (e: RadioChangeEvent) => {
    switch (e.target.value) {
      case "patient":
        setProService(true);
        setProCultivationService(false);
        break;

      case "cultivation":
        setProService(false);
        setProCultivationService(true);
        break;

      default:
        setProService(false);
        setProCultivationService(false);
        break;
    }
  };

  const buttonDisabled = isSubmitting || !agreementAccepted;

  if (status === "process") {
    return (
      <>
        {jurisdictionInfo.proUpgradeAvailable && (
          <Card style={{ marginBottom: "15px" }}>
            <div style={{ float: "left" }}>
              <Text strong>UPGRADE OPTIONS</Text>
              <br />
              {upgradeText &&
                upgradeText.map((text, i) => (
                  <>
                    <Text>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Text>
                    {upgradeText[i + 1] && <br />}
                  </>
                ))}
            </div>
          </Card>
        )}
        <div>
          {jurisdictionInfo.proUpgradeAvailable &&
            !jurisdictionInfo.proCultivationUpgradeAvailable && (
              <>
                <Checkbox
                  checked={proService}
                  onChange={upgradeProServiceCheckboxOnChange}
                >
                  <Text strong>
                    Yes! Upgrade me to <i>Pro Service</i> for $
                    {jurisdictionInfo.proPrice} more!
                  </Text>
                </Checkbox>
                <br />
              </>
            )}
          {jurisdictionInfo.proCultivationUpgradeAvailable &&
            !jurisdictionInfo.sixteenOzUpgradeAvailable && (
              <>
                <Radio.Group
                  name="upgradeOption"
                  onChange={upgradeRadioOnChange}
                >
                  <Radio value="">No thanks, I'm good</Radio>
                  <br />
                  <Radio value="patient">
                    Patient Application - ${jurisdictionInfo.proPrice}
                  </Radio>
                  <br />
                  <Radio value="cultivation">
                    Patient + Cultivation - $
                    {jurisdictionInfo.proPrice +
                      jurisdictionInfo.proCultivationPrice}
                  </Radio>
                </Radio.Group>
                <br />
              </>
            )}
          {jurisdictionInfo.sixteenOzUpgradeAvailable && (
            <>
              <Checkbox checked={proService} onChange={upgradePatientChanged}>
                Patient Application - ${jurisdictionInfo.proPrice}
              </Checkbox>
              <br />
              <Checkbox
                checked={proCultivationService}
                onChange={upgradeCultivationChanged}
              >
                Patient + Cultivation - $
                {jurisdictionInfo.proPrice +
                  jurisdictionInfo.proCultivationPrice}
              </Checkbox>
              <br />
              <br />
              <Checkbox
                checked={sixteenOzService}
                onChange={upgradeSixteenOzCheckboxOnChange}
              >
                16oz possession - ${jurisdictionInfo.sixteenOzUpgradePrice}
              </Checkbox>
              <br />
              <br />
            </>
          )}
          {(proService || proCultivationService) && (
            <>
              <Text strong>Great! To do that, we need a couple things:</Text>
              <br />
              <br />
              <UploadSelfie
                selfieFile={selfieFile}
                setSelfieFile={setSelfieFile}
              />
              <br />
              <br />
              <UploadId
                idFile={idFile}
                setIdFile={setIdFile}
                jurisdictionInfo={jurisdictionInfo}
              />
              <br />
              {jurisdictionInfo.proAlternativeInfo && (
                <>
                  <br />
                  <ProAlternative
                    proAlternative={proAlternativeService}
                    setProAlternative={setProAlternativeService}
                    docFile={proAlternativeDocFile}
                    setDocFile={setProAlternativeDocFile}
                    proAlternativeInfo={jurisdictionInfo.proAlternativeInfo}
                  />
                </>
              )}
            </>
          )}
          <br />
          <br />
          {jurisdictionInfo.name} Review:
          {renewalPrice.format()}
          <br />
          {proService && (
            <>
              {`Pro Service Addon: ${currency(
                (proAlternativeService
                  ? jurisdictionInfo.proAlternativeInfo?.price
                  : jurisdictionInfo.proPrice)!
              ).format()}`}
              <br />
            </>
          )}
          {proCultivationService && (
            <>
              {`Pro Patient + Cultivation Addon: ${currency(
                (proAlternativeService
                  ? jurisdictionInfo.proAlternativeInfo!.price
                  : jurisdictionInfo.proPrice) +
                  jurisdictionInfo.proCultivationPrice
              ).format()}`}
              <br />
            </>
          )}
          {sixteenOzService && (
            <>
              {`16oz Possession Upgrade: ${currency(
                jurisdictionInfo.sixteenOzUpgradePrice
              ).format()}`}
              <br />
            </>
          )}
          <Row align="middle">
            <Col>
              <br />
              <Agreement
                agreementAccepted={agreementAccepted}
                setAgreementAccepted={setAgreementAccepted}
              />
              <br />
              <br />
              <Button
                disabled={buttonDisabled}
                type="primary"
                onClick={onClick}
              >
                Finalize
              </Button>
            </Col>
            {isSubmitting && (
              <Col>
                <Spin style={{ margin: "5px 0px 0px 5px" }} />
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }

  if (status === "finish") {
    return <Text strong>Done!</Text>;
  }

  return null;
}
