import React from 'react';

export function FinalizeTitle() {
  return (
    <span style={{ float: 'left' }}>
      <div className="ant-steps-item-icon">
        <span className="ant-steps-icon">4</span>
      </div>
      Finalize
    </span>
  );
}

export default FinalizeTitle;
