import React from 'react';
import { Alert, Form } from 'antd';

function PaymentAlert() {
  return (
    <Form.Item>
      <Alert
        message="Error"
        description="There was problem processing your credit card. Please check your information and try again."
        type="error"
        showIcon
      />
    </Form.Item>
  );
}

export default PaymentAlert;
