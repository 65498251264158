import React from 'react';
import { Form, Input } from 'antd';

function NameGroup() {
  return (
    <Form.Item label="Name" required>
      <Input.Group compact>
        <Form.Item
          name={['name', 'first']}
          noStyle
          rules={[{ required: true, message: 'First name is required' }]}
        >
          <Input placeholder="First" />
        </Form.Item>
        <Form.Item
          name={['name', 'middle']}
          noStyle
          rules={[{ required: false }]}
        >
          <Input placeholder="Middle (optional)" />
        </Form.Item>
        <Form.Item
          name={['name', 'last']}
          noStyle
          rules={[{ required: true, message: 'Last name is required' }]}
        >
          <Input placeholder="Last" />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
}

export default NameGroup;
