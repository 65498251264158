import React from 'react';
import { Button } from 'antd';
import moment from 'moment';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface TimeListItemProps {
  time: string;
  tempSelection: Date | undefined;
  setTempSelection: (tempSelection: Date) => void;
  timeSelected: (appointment: Date) => void;
}

function TimeListItem(props: TimeListItemProps) {
  const {
    time, tempSelection, setTempSelection, timeSelected,
  } = props;

  const openTime = new Date(Date.parse(time));

  if (moment(openTime).isSame(tempSelection)) {
    return (
      <div>
        <div className="select-time-box">
          {moment(openTime).format('h:mm A')}
          <br />
          <Button
            type="primary"
            title="Click"
            onClick={() => {
              // getAppInsights().trackEvent({ name: 'Confirmed Appointment Time', properties: { openTime } });
              timeSelected(openTime);
            }}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Button
        type="link"
        className="time-button"
        title="Click"
        onClick={() => {
          // getAppInsights().trackEvent({ name: 'Selected Appointment Time', properties: { openTime } });
          setTempSelection(openTime);
        }}
      >
        {moment(openTime).format('h:mm A')}
      </Button>
    </div>
  );
}

export default TimeListItem;
