import { Checkbox, Col, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { JurisdictionInfo } from '../../shared/Types';

export interface AgreementProps {
  isResident: boolean;
  setIsResident: (checked: boolean) => void;
  jurisdictionInfo: JurisdictionInfo;
}

function Agreement(props: AgreementProps) {
  const { isResident, setIsResident, jurisdictionInfo } = props;

  const onChange = (e: CheckboxChangeEvent) => {
    setIsResident(e.target.checked);
  };

  return (
    <Col sm={{ offset: 5 }}>
      <Form.Item
        name="resident"
        rules={[
          () => ({
            validator() {
              if (isResident) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(`You must be a ${jurisdictionInfo.name} resident to qualify.`));
            },
          }),
        ]}
      >
        <Checkbox checked={isResident} onChange={onChange}>
          Yes, I&apos;m a
          {' '}
          {jurisdictionInfo.name}
          {' '}
          Resident
        </Checkbox>
      </Form.Item>
    </Col>
  );
}

export default Agreement;
