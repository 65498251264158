import { StepProps } from 'antd/lib/steps';
import React from 'react';
import { Steps } from 'antd';
import { Questionnaire, QuestionnaireProps, Questions } from './Main';
import { QuestionnaireTitle, QuestionnaireTitleProps } from './Title';

const { Step } = Steps;

export interface QuestionnaireStepProps {
  setCurrent: (cur: number) => void;
  isEditable: boolean;
  questionnaire: Questions | undefined;
  onFinish: (questions: Questions) => void;
}

export function QuestionnaireStep(props: QuestionnaireStepProps & StepProps) {
  const {
    setCurrent, isEditable, questionnaire, onFinish, ...stepProps
  } = props;
  const { status } = stepProps;

  const questionnaireTitleProps: QuestionnaireTitleProps = {
    setCurrent,
    isEditable,
    status,
  };

  const questionnaireProps: QuestionnaireProps = ({
    status,
    questionnaire,
    onFinish,
  });

  return (
    <Step
      key="Questionnaire"
      title={QuestionnaireTitle(questionnaireTitleProps)}
      description={Questionnaire(questionnaireProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}
