import React from "react";
import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import { FinalizeTitle } from "./Title";
import { Finalize, FinalizeProps } from "./Main";
import { JurisdictionInfo } from "../../shared/Types";

const { Step } = Steps;

export interface FinalizeStepProps {
  upgradeOption?: "patient" | "cultivation";
  proAlternative: boolean;
  upgradeSixteenOz: boolean;
  isSubmitting: boolean;
  finalizeClicked: () => void;
  jurisdictionInfo: JurisdictionInfo;
}

export function FinalizeStep(props: FinalizeStepProps & StepProps) {
  const {
    upgradeOption,
    proAlternative,
    upgradeSixteenOz,
    isSubmitting,
    finalizeClicked,
    jurisdictionInfo,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const finalizeProps: FinalizeProps = {
    upgradeOption,
    proAlternative,
    upgradeSixteenOz,
    isSubmitting,
    status,
    onFinish: finalizeClicked,
    jurisdictionInfo,
  };

  return (
    <Step
      key="Finalize"
      title={FinalizeTitle()}
      description={Finalize(finalizeProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}
