import React from 'react';
import { Form, Select } from 'antd';
import Conditions from './Conditions';

const { Option } = Select;

function QualifyingCondition() {
  return (
    <Form.Item
      name="qualifyingCondition"
      label="Qualifying Condition"
      rules={[{ required: true, message: 'A Qualifying Condition is required' }]}
    >
      <Select placeholder="Select your Qualifying Condition">
        {Conditions.map((condition) => (
          <Option key={condition} value={condition}>{condition}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default QualifyingCondition;
