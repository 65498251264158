import {
  Card, Form, Input, Typography,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useState } from 'react';
import { GetStarted as GetStartedApi } from '../../shared/ApiWrapper';
// import { getAppInsights } from '../../shared/TelemetryService';
import { BookingInformation, JurisdictionInfo } from '../../shared/Types';
import AgeCheck from './AgeCheck';
import EmailInput from './EmailInput';
import NameGroup from './NameGroup';
import NextButton from './NextButton';
import PhoneInput from './PhoneInput';
import QualifyingCondition from './QualifyingCondition';
import ResidentCheck from './ResidentCheck';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface GetStartedProps {
  setCurrent: (current: number) => void;
  bookingInformation: BookingInformation;
  setBookingInformation: (bookingInfo: BookingInformation) => void;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  qualifyingCondition: string;
  setQualifyingCondition: (condition: string) => void;
  jurisdictionInfo: JurisdictionInfo;
}

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function GetStarted(props: GetStartedProps) {
  const {
    setCurrent,
    bookingInformation,
    setBookingInformation,
    qualifyingCondition,
    setQualifyingCondition,
    status,
    jurisdictionInfo,
  } = props;

  const [isResident, setIsResident] = useState<boolean>(false);
  const [isEighteen, setIsEighteen] = useState<boolean>(false);

  const finishFunc = (values: Store) => {
    const booking: BookingInformation = {
      name: { first: values.name.first, middle: values.name.middle, last: values.name.last },
      email: values.email,
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
      },
      phone: values.phone,
      county: values.county,
      jurisdiction: values.jurisdiction,
    };

    GetStartedApi({ ...values, phone: clearNumber(values.phone) });
    setBookingInformation(booking);
    setQualifyingCondition(values.qualifyingCondition);
    // getAppInsights().trackEvent({ name: 'Submitted Get Started' });
    setCurrent(1);
  };

  if (status === 'process') {
    return (
      <div>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ float: 'left' }}>
            <Text strong>Ready, Let&apos;s Go!</Text>
            <br />
            <Text>
              To qualify you must be a
              {' '}
              {jurisdictionInfo.name}
              {' '}
              resident, 18 years of age or older
              and have one or more of the qualifying conditions listed below. If you do not see your
              condition listed or have any special circumstances, please call and speak to our
              support team at 800.478.1984.
              {' '}
            </Text>
          </div>
        </Card>
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          name="register"
          onFinish={finishFunc}
          initialValues={{ ...bookingInformation, qualifyingCondition, emailPermission: true }}
          scrollToFirstError
        >
          <ResidentCheck
            isResident={isResident}
            setIsResident={setIsResident}
            jurisdictionInfo={jurisdictionInfo}
          />
          <AgeCheck isEighteen={isEighteen} setIsEighteen={setIsEighteen} />
          <QualifyingCondition />
          <NameGroup />
          <PhoneInput />
          <EmailInput />
          <NextButton />
          <Form.Item name="jurisdiction" hidden>
            <Input />
          </Form.Item>
        </Form>
      </div>
    );
  }

  if (bookingInformation) {
    return (
      <Text strong>
        {bookingInformation.name.first}
        {' '}
        {bookingInformation.name.last}
      </Text>
    );
  }

  return null;
}
