import { RcFile } from "antd/lib/upload";
import { Questions } from "../Renewal/Questionnaire/Main";
import { ScheduleRenewal } from "./ApiWrapper";
import { uploadId, uploadproAlternativeDoc, uploadSelfie } from "./ImageUtils";
// import { getAppInsights } from './TelemetryService';
import {
  BillingInformation,
  BookingInformation,
  JurisdictionInfo,
  RenewalDetails,
  RenewalType,
} from "./Types";

export interface BookRenewalParams {
  setCurrent: Function;
  setIsSubmitting: (isSubmitting: boolean) => void;
  token: string;
  confirmEmail: string;
  billingInformation: BillingInformation | undefined;
  setPaymentError: Function;
  setSubmitted: (submitted: boolean) => void;
  bookingInformation: BookingInformation;
  questions: Questions;
  idFile: RcFile | undefined;
  selfieFile: RcFile | undefined;
  proAlternativeDocFile: RcFile | undefined;
  proService: boolean;
  proCultivationService: boolean;
  proAlternativeService: boolean;
  sixteenOzService: boolean;
  jurisdictionInfo: JurisdictionInfo;
  renewalType: RenewalType;
  appointmentDate: Date | undefined;
}

export async function BookRenewal(params: BookRenewalParams) {
  const {
    token,
    confirmEmail,
    setIsSubmitting,
    billingInformation,
    setSubmitted,
    bookingInformation,
    questions,
    proService,
    proCultivationService,
    proAlternativeService,
    sixteenOzService,
    idFile,
    selfieFile,
    proAlternativeDocFile,
    jurisdictionInfo,
    renewalType,
    appointmentDate,
  } = params;
  try {
    // getAppInsights().trackEvent({ name: 'Booking Renewal Started' });
    setIsSubmitting(true);
    const { billingName, certificate, cvc } = billingInformation!;
    const number = billingInformation!.cardNumber;
    const expiry =
      billingInformation!.expiryMonth +
      billingInformation!.expiryYear.substring(2, 4);
    const { first: firstName, last: lastName } = bookingInformation.name;
    const {
      street1: address1,
      street2: address2,
      city,
      state: addressState,
      zip,
    } = bookingInformation.address;
    const { phone, email, county } = bookingInformation;

    const scheduleRenewalDetails: RenewalDetails = {
      token,
      confirmEmail,
      firstName,
      lastName,
      phone,
      email,
      county,
      certificate,
      number,
      billingName,
      expiry,
      cvc,
      address1,
      address2,
      city,
      state: addressState,
      zip,
      questions,
      proService,
      proCultivationService,
      proAlternativeService,
      sixteenOzService,
      jurisdiction: jurisdictionInfo.abbr,
      renewalType,
      appointmentDate,
    };

    const result = await ScheduleRenewal(scheduleRenewalDetails);

    if (idFile) {
      uploadId(result.appointmentId, result.key, idFile);
    }
    if (selfieFile) {
      uploadSelfie(result.appointmentId, result.key, selfieFile);
    }
    if (proAlternativeDocFile) {
      uploadproAlternativeDoc(
        result.appointmentId,
        result.key,
        proAlternativeDocFile
      );
    }

    setSubmitted(true);
    // getAppInsights().trackEvent({ name: 'Booking Renewal Succeeded' });

    params.setCurrent(5);
  } catch (error) {
    params.setPaymentError(true);
    // getAppInsights().trackEvent({ name: 'Booking Renewal Failed' });
    params.setCurrent(3);
  } finally {
    setIsSubmitting(false);
  }
}
