import React from 'react';
import { Form, Typography, Card } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { BookingInformation, JurisdictionInfo } from '../../shared/Types';
// import { getAppInsights } from '../../shared/TelemetryService';
import NameGroup from './NameGroup';
import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import ConfirmEmailInput from './ConfirmEmailInput';
import MailingAddressGroup from './MailingAddressGroup';
import CountySelect from './CountySelect';
import NextButton from './NextButton';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface YourInformationProps {
  setCurrent: (current: number) => void;
  bookingInformation: BookingInformation;
  setBookingInformation: (bookingInfo: BookingInformation) => void;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  jurisdictionInfo: JurisdictionInfo;
}

export function YourInformation(props: YourInformationProps) {
  const {
    setCurrent, bookingInformation, setBookingInformation, status, jurisdictionInfo,
  } = props;

  const finishFunc = (values: Store) => {
    const booking: BookingInformation = {
      name: { first: values.name.first, middle: values.name.middle, last: values.name.last },
      email: values.email,
      address: {
        street1: values.address.street1,
        street2: values.address.street2,
        city: values.address.city,
        state: values.address.state,
        zip: values.address.zip,
      },
      phone: values.phone,
      county: values.county,
      jurisdiction: values.jurisdiction,
    };
    setBookingInformation(booking);
    // getAppInsights().trackEvent({ name: 'Submitted Your Information' });
    setCurrent(3);
  };

  if (status === 'process') {
    return (
      <div>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ float: 'left' }}>
            <Text strong>We look forward to seeing you!</Text>
            <br />
            <Text>Please tell us a little about you.</Text>
          </div>
        </Card>
        <Form
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          name="register"
          onFinish={finishFunc}
          initialValues={bookingInformation}
          scrollToFirstError
        >
          <NameGroup />
          <PhoneInput />
          <EmailInput />
          <ConfirmEmailInput />
          <MailingAddressGroup />
          <CountySelect counties={jurisdictionInfo.counties} />
          <NextButton />
        </Form>
      </div>
    );
  }

  if (bookingInformation) {
    return (
      <Text strong>
        {bookingInformation.name.first}
        {' '}
        {bookingInformation.name.last}
      </Text>
    );
  }

  return null;
}
