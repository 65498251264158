import React, { useEffect, useRef } from 'react';
import { Button } from 'antd';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface ChooseAppointmentTitleProps {
  isChangeable: boolean;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  onClick: () => void;
}

export function ChooseAppointmentTitle(props: ChooseAppointmentTitleProps) {
  const {
    status, isChangeable, onClick,
  } = props;
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (status === 'process') {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [status]);

  return (
    <>
      <span ref={ref} style={{ float: 'left' }}>
        <div className="ant-steps-item-icon">
          <span className="ant-steps-icon">2</span>
        </div>
        Choose Appointment Time
      </span>
      {status === 'finish' && isChangeable
        && (
          <span style={{ float: 'right' }}>
            <Button
              title="Click"
              onClick={() => {
                // getAppInsights().trackEvent({ name: 'Go back to Choose Appointment' });
                onClick();
              }}
            >
              Change
            </Button>
          </span>
        )}
    </>
  );
}
