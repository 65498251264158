import { Steps } from "antd";
import { Store } from "antd/lib/form/interface";
import { StepProps } from "antd/lib/steps";
import currency from "currency.js";
import React from "react";
import { PaymentInformation, PaymentInformationProps } from "./Main";
import { PaymentInformationTitle, PaymentInformationTitleProps } from "./Title";

import "react-credit-cards/es/styles-compiled.css";
import {
  BillingInformation,
  JurisdictionInfo,
  RenewalType,
} from "../../shared/Types";

const { Step } = Steps;

export interface PaymentInformationStepProps {
  setCurrent: (cur: number) => void;
  isSubmitting: boolean;
  submitted: boolean;
  paymentError: boolean;
  setPaymentError: (err: boolean) => void;
  billingInformation: BillingInformation;
  setBillingInformation: (info: BillingInformation) => void;
  paymentFormFinish: (values: Store, discount: currency) => void;
  jurisdictionInfo: JurisdictionInfo;
  renewalType: RenewalType;
}

function PaymentInformationStep(
  props: PaymentInformationStepProps & StepProps
) {
  const {
    setCurrent,
    isSubmitting,
    submitted,
    paymentError,
    setPaymentError,
    billingInformation,
    setBillingInformation,
    paymentFormFinish,
    jurisdictionInfo,
    renewalType,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const paymentInformationTitleProps: PaymentInformationTitleProps = {
    setCurrent,
    isEditable: !isSubmitting && !submitted,
    status,
  };

  const paymentInformationProps: PaymentInformationProps = {
    paymentError,
    billingInformation,
    status,
    enablePromoCode: true,
    jurisdictionInfo,
    renewalType,
  };

  return (
    <Step
      key="Payment Information"
      title={PaymentInformationTitle(paymentInformationTitleProps)}
      description={PaymentInformation(
        paymentInformationProps,
        paymentFormFinish
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default PaymentInformationStep;
