import { Form, Input } from 'antd';
import currency from 'currency.js';
import React, { useState } from 'react';
import { CheckCode } from './ApiWrapper';
import { JurisdictionInfo } from './Types';

export interface PromoCodeInputProps {
  setDiscount: Function;
  setFocus: Function;
  enablePromoCode: boolean;
  jurisdictionInfo: JurisdictionInfo;
  appointmentType: 'MMJ' | 'RENEWAL';
}

function PromoCodeInput(props: PromoCodeInputProps) {
  const {
    setDiscount, setFocus, enablePromoCode, jurisdictionInfo, appointmentType,
  } = props;

  const [hasFeedback, setHasFeedback] = useState<boolean>(false);

  const onChange = () => {
    setHasFeedback(false);
  };

  return (
    <Form.Item
      name="certificate"
      validateTrigger={['onBlur']}
      hasFeedback={hasFeedback}
      hidden={!enablePromoCode}
      rules={[
        {
          validator: async (rule, value) => {
            if (value === null || value === '') {
              setDiscount(undefined);
              return Promise.resolve();
            }

            try {
              setHasFeedback(true);
              const checkCode = await CheckCode(value, jurisdictionInfo.abbr, appointmentType);
              const discountAmount = currency(checkCode.discountAmount);
              setDiscount(discountAmount);
              return await Promise.resolve();
            } catch (error) {
              return Promise.reject(error);
            }
          },
          validateTrigger: 'onBlur',
        },
      ]}
    >
      <Input
        onFocus={() => setFocus(undefined)}
        placeholder="Promo Code (optional)"
        onChange={onChange}
      />
    </Form.Item>
  );
}

export default PromoCodeInput;
