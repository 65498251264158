import React, { useEffect, useRef } from 'react';

export interface ConfirmationTitleProps {
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}

export function ConfirmationTitle(props: ConfirmationTitleProps) {
  const { status } = props;
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (status === 'process') {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [status]);

  return (
    <span ref={ref} style={{ float: 'left' }}>
      <div className="ant-steps-item-icon">
        <span className="ant-steps-icon">6</span>
      </div>
      Confirmation
    </span>
  );
}

export default ConfirmationTitle;
