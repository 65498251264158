import React from 'react';
import { Steps } from 'antd';
import { StepProps } from 'antd/lib/steps';

import { BookingInformation } from '../../shared/Types';
import { Confirmation, ConfirmationProps } from './Main';
import ConfirmationTitle from './Title';

const { Step } = Steps;

export interface ConfirmationStepProps {
  bookingInformation: BookingInformation;
}

function ConfirmationStep(props: ConfirmationStepProps & StepProps) {
  const { bookingInformation, ...stepProps } = props;
  const { status } = stepProps;

  const confirmationProps: ConfirmationProps = {
    status,
  };

  return (
    <Step
      key="Confirmation"
      title={ConfirmationTitle()}
      description={Confirmation(confirmationProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default ConfirmationStep;
