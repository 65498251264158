import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Louisiana from "./Louisiana";
import LouisianaRenewal from "./LouisianaRenewal";
import LouisianaUpgrade from "./LouisianaUpgrade";
import Missouri from "./Missouri";
import MissouriRapidReturn from "./MissouriRapidReturn";
import MissouriRenewal from "./MissouriRenewal";
import MissouriUpgrade from "./MissouriUpgrade";
import Oklahoma from "./Oklahoma";
import OklahomaRenewal from "./OklahomaRenewal";
import OklahomaUpgrade from "./OklahomaUpgrade";
import Ohio from "./Ohio";
import OhioRenewal from "./OhioRenewal";
import Pennsylvania from "./Pennsylvania";
import PennsylvaniaUpgrade from "./PennsylvaniaUpgrade";
import PennsylvaniaRenewal from "./PennsylvaniaRenewal";
// import TelemetryProvider from './shared/TelemetryProvider';

// const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY;

function RedirectMO(props: RouteComponentProps<any>) {
  const { location } = props;
  return (
    <Redirect
      to={{
        pathname: "/mo",
        search: location.search,
      }}
    />
  );
}

function RedirectRenew(props: RouteComponentProps<any>) {
  const { location } = props;
  return (
    <Redirect
      to={{
        pathname: "/mo/renew",
        search: location.search,
      }}
    />
  );
}

function RedirectUpgrade(props: RouteComponentProps<any>) {
  const { location } = props;
  return (
    <Redirect
      to={{
        pathname: "/mo/upgrade",
        search: location.search,
      }}
    />
  );
}

function AppWithRouterAccess() {
  return (
    // <TelemetryProvider instrumentationKey={instrumentationKey}>
    <Switch>
      <Route exact path="/" component={RedirectMO} />
      <Route exact path="/renew" component={RedirectRenew} />
      <Route exact path="/upgrade" component={RedirectUpgrade} />
      <Route exact path="/la">
        <Louisiana />
      </Route>
      <Route exact path="/la/renew">
        <LouisianaRenewal />
      </Route>
      <Route exact path="/la/upgrade">
        <LouisianaUpgrade />
      </Route>
      <Route exact path="/(missouri|mo)">
        <Missouri />
      </Route>
      <Route exact path="/mo/renew">
        <MissouriRenewal />
      </Route>
      <Route exact path="/mo/rapidreturn">
        <MissouriRapidReturn />
      </Route>
      <Route exact path="/mo/upgrade">
        <MissouriUpgrade />
      </Route>
      <Route exact path="/ok">
        <Oklahoma />
      </Route>
      <Route exact path="/ok/renew">
        <OklahomaRenewal />
      </Route>
      <Route exact path="/ok/upgrade">
        <OklahomaUpgrade />
      </Route>
      <Route exact path="/oh">
        <Ohio />
      </Route>
      <Route exact path="/oh/renew">
        <OhioRenewal />
      </Route>
      <Route exact path="/pa">
        <Pennsylvania />
      </Route>
      <Route exact path="/pa/upgrade">
        <PennsylvaniaUpgrade />
      </Route>
      <Route exact path="/pa/renew">
        <PennsylvaniaRenewal />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
    // {/* </TelemetryProvider> */}
  );
}

export default AppWithRouterAccess;
