import React from 'react';
import { Form, Input } from 'antd';
import { Focused } from 'react-credit-cards';

export interface ExpiryInputProps {
  setFocus: (field: Focused) => void;
}

function ExpiryYearInput(props: ExpiryInputProps) {
  const { setFocus } = props;

  return (
    <Form.Item
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 24 },
      }}
      name="expiryYear"
      validateTrigger={['onChange', 'onBlur']}
      rules={[
        { required: true, message: 'Expiration Year is required' },
        { validateTrigger: 'onBlur', pattern: /\d\d\d\d/, message: 'Expiration Year be in YYYY format.' },
      ]}
    >
      <Input onFocus={() => setFocus('expiry')} placeholder="Year" autoComplete="cc-exp-year" maxLength={4} />
    </Form.Item>
  );
}

export default ExpiryYearInput;
