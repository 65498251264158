import React, { useEffect, useRef } from 'react';
import { Button } from 'antd';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface YourInformationTitleProps {
  setCurrent: (current: number) => void;
  isSubmitting: boolean;
  submitted: boolean;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}

export function YourInformationTitle(props: YourInformationTitleProps) {
  const {
    setCurrent, isSubmitting, submitted, status,
  } = props;
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (status === 'process') {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [status]);

  return (
    <>
      <span ref={ref} style={{ float: 'left' }}>
        <div className="ant-steps-item-icon">
          <span className="ant-steps-icon">2</span>
        </div>
        Your Information
      </span>
      {status === 'finish' && !isSubmitting && !submitted
        && (
          <span style={{ float: 'right' }}>
            <Button
              title="Click"
              onClick={() => {
                // getAppInsights().trackEvent({ name: 'Go back to Your Information' });
                setCurrent(2);
              }}
            >
              Change
            </Button>
          </span>
        )}
    </>
  );
}
