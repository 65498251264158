import currency from "currency.js";
import React from "react";
import Schedule from "./NewAppointment";
import { jurisdictionInfo } from "./shared/Constants";

const headerText = `
  WITH 420ID YOU WILL BE APPROVED OR YOU PAY NOTHING. All reviews are conducted via video conference from any smartphone or computer that is equipped with a
  video camera. There is no need to visit an office. Most reviews take between 10-15 minutes with one of our friendly, expert medical doctors will help
  guide you through the qualification process.
`;

const upgradeText = [
  `Upgrade to <i>Pro Service</i>. This option includes us completing your state application and paying your state application fee (${currency(
    jurisdictionInfo.OK.applicationFee
  ).format()}) on your behalf. This option is designed for anyone who is uncomfortable with computers and technology and would like extra assistance.`,
];
function Oklahoma() {
  return (
    <Schedule
      headerText={headerText}
      upgradeText={upgradeText}
      jurisdictionInfo={jurisdictionInfo.OK}
    />
  );
}

export default Oklahoma;
