import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import { RcFile } from "antd/lib/upload";
import React from "react";
import { BillingInformation, JurisdictionInfo } from "../../shared/Types";
import { Finalize, FinalizeProps } from "./Main";
import { FinalizeTitle, FinalizeTitleProps } from "./Title";

const { Step } = Steps;

export interface FinalizeStepProps {
  isSubmitting: boolean;
  billingInformation: BillingInformation | undefined;
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  finalizeClicked: (
    proService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}

function FinalizeStep(props: FinalizeStepProps & StepProps) {
  const {
    isSubmitting,
    billingInformation,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    finalizeClicked,
    jurisdictionInfo,
    upgradeText,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const finalizeProps: FinalizeProps = {
    isSubmitting,
    billingInformation,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    status,
    finalizeClicked,
    jurisdictionInfo,
    upgradeText,
  };

  const finalizeTitleProps: FinalizeTitleProps = { status };

  return (
    <Step
      key="Finalize"
      title={FinalizeTitle(finalizeTitleProps)}
      description={Finalize(finalizeProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default FinalizeStep;
