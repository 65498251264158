import React from 'react';
import { Form, Input } from 'antd';

function ConfirmEmailInput() {
  return (
    <Form.Item
      name="confirm"
      label="Confirm E-mail"
      dependencies={['email']}
      hasFeedback
      rules={[
        {
          required: true,
          message: 'Please confirm your email!',
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue('email') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two email addresses that you entered do not match!'));
          },
        }),
      ]}
    >
      <Input />
    </Form.Item>
  );
}

export default ConfirmEmailInput;
