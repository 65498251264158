import React from 'react';
import { Button, Row, Col } from 'antd';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface PageControlProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  hasNextPage: boolean;
}

function PageControl(props: PageControlProps) {
  const { currentPage, setCurrentPage, hasNextPage } = props;

  const prevPage = () => {
    // getAppInsights().trackEvent({ name: 'Go to appointment page:', properties: { newPage: currentPage - 1 } });
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    // getAppInsights().trackEvent({ name: 'Go to appointment page:', properties: { newPage: currentPage + 1 } });
    setCurrentPage(currentPage + 1);
  };

  return (
    <Row>
      <Col xs={24} style={{ margin: '10px 0px 10px 0px' }}>
        <span style={{ float: 'left' }}>
          {currentPage > 0 && (
            <Button title="Click" onClick={prevPage}>
              &lt; More Times
            </Button>
          )}
        </span>
        <span style={{ float: 'right' }}>
          {hasNextPage && (
            <Button title="Click" onClick={nextPage}>
              More Times &gt;
            </Button>
          )}
        </span>
      </Col>
    </Row>
  );
}

export default PageControl;
