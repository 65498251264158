import { StepProps } from 'antd/lib/steps';
import { Steps } from 'antd';
import React from 'react';
import { BookingInformation, JurisdictionInfo } from '../../shared/Types';
import { YourInformation, YourInformationProps } from './Main';
import { YourInformationTitle, YourInformationTitleProps } from './Title';

const { Step } = Steps;

export interface YourInformationStepProps {
  setCurrent: (current: number) => void;
  isSubmitting: boolean;
  submitted: boolean;
  bookingInformation: BookingInformation;
  setBookingInformation: (bookingInfo: BookingInformation) => void;
  jurisdictionInfo: JurisdictionInfo;
}

function YourInformationStep(props: YourInformationStepProps & StepProps) {
  const {
    setCurrent, isSubmitting, submitted, bookingInformation,
    setBookingInformation, jurisdictionInfo, ...stepProps
  } = props;
  const { status } = stepProps;

  const yourInformationTitleProps: YourInformationTitleProps = {
    setCurrent,
    isSubmitting,
    submitted,
    status,
  };

  const yourInformationProps: YourInformationProps = {
    setCurrent,
    bookingInformation,
    setBookingInformation,
    status,
    jurisdictionInfo,
  };

  return (
    <Step
      key="Your Information"
      title={YourInformationTitle(yourInformationTitleProps)}
      description={YourInformation(yourInformationProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}

export default YourInformationStep;
