import React from "react";
import Schedule from "./NewAppointment";
import { jurisdictionInfo } from "./shared/Constants";

const headerText = `
  WITH 420ID YOU WILL BE APPROVED OR YOU PAY NOTHING. All reviews are conducted via video conference from any smartphone or computer that is equipped with a
  video camera. There is no need to visit an office. Most reviews take between 10-15 minutes with one of our friendly, expert medical doctors will help
  guide you through the qualification process.
`;

function Ohio() {
  return (
    <Schedule
      headerText={headerText}
      upgradeText={[]}
      jurisdictionInfo={jurisdictionInfo.OH}
    />
  );
}

export default Ohio;
