import { Col, Row, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

export interface ConfirmationProps {
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}

export function Confirmation(props: ConfirmationProps) {
  const { status } = props;

  if (status === 'process') {
    return (
      <>
        <Row align="middle">
          <Col
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 10, offset: 7 }}
            style={{ textAlign: 'center' }}
          >
            <span className="success">Success!</span>
            <br />
            <Text strong>Your Upgrade Request has been Received.</Text>
            <br />
            <Text style={{ fontStyle: 'italic' }}>
              For your privacy all billing on credit cards processed by 420ID will be under the name
              Pro Medical Reviews
            </Text>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col xs={18} sm={{ span: 16, offset: 4 }} style={{ textAlign: 'center' }}>
            <Text>
              In the next two (2) business days your physician will email you with details regarding
              your next steps.
            </Text>
          </Col>
        </Row>
      </>
    );
  }

  return null;
}
