import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import AppWithRouterAccess from './AppWithRouterAccess';

import './App.css';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('1783230948359199', undefined, options);
ReactPixel.pageView(); // For tracking page view
ReactPixel.track('InitiateCheckout', null);

function App() {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
}

export default App;
