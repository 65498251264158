import React from "react";
import Renewal from "./Renewal";
import { jurisdictionInfo } from "./shared/Constants";
import { RenewalType } from "./shared/Types";

const headerText = `<b>It's time to renew your Ohio Medical 420ID Card!</b>
  <br />
  <br />
  You Qualify for Rapid Renewal!
  <br />
  <br />
  It's time to renew your Ohio Medical 420ID card and because you used 420ID last year, you qualify for Rapid Renewal. Simply complete the short online questionnaire. Renewal requests must be completed between 60 - 30 days prior to your current expiration date.
  <br />
  <br />
  Once you complete the questionnaire and payment your 420ID Physician will review your answers and call you to discuss your prior year's experience with you. This renewal can be done via your phone. Video calls are not required when you renew with 420ID.
  <br />
  <br />
  Renewal Cost: $${jurisdictionInfo.OH.renewalPrice} ($50 savings).`;

const smallPrint =
  "*You can still choose to have a video appointment with the doctor if you prefer. Additionally, if you have developed new conditions or side effects, the doctor may require a video appointment before qualifying you.";

function OhioRenewal() {
  return (
    <Renewal
      headerText={headerText}
      smallPrint={smallPrint}
      jurisdictionInfo={jurisdictionInfo.OH}
      upgradeText={[]}
      renewalType={RenewalType.Standard}
    />
  );
}

export default OhioRenewal;
