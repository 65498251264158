import React from 'react';
import { Button, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
// import { getAppInsights } from './TelemetryService';
import { JurisdictionInfo } from './Types';

const { Text } = Typography;

export interface UploadIdProps {
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void,
  jurisdictionInfo: JurisdictionInfo;
}

function UploadId(props: UploadIdProps) {
  const { idFile, setIdFile, jurisdictionInfo } = props;

  const beforeUpload = (file: RcFile) => {
    setIdFile(file);
    // getAppInsights().trackEvent({ name: 'Id Chosen' });
    return false;
  };

  return (
    <>
      <Text strong style={{ color: 'red' }}>Upload State ID Photo: </Text>
      <Text>
        Please provide a clear photo of the front of your valid
        {' '}
        {jurisdictionInfo.name}
        {' '}
        State ID.
      </Text>
      <br />
      <Upload id="uploadStateId" showUploadList={false} beforeUpload={beforeUpload}>
        <Button>
          <UploadOutlined />
          Click to Upload
        </Button>
      </Upload>
      {' '}
      {idFile && <CheckOutlined style={{ fontSize: 16, color: 'green' }} />}
    </>
  );
}

export default UploadId;
