import React from 'react';
import { Form, Select, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select;

const options = ['TV', 'Radio', 'Billboard', 'Dispensary', 'Retail Store', 'Search', 'Referred by Friend', 'Social Media', 'Other Website', 'Something Else'];

function HowDidYouHear() {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Text>How did you hear about us?</Text>
      </div>
      <Form.Item
        name="howDidYouHear"
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          { required: true, message: 'Please tell us how you heard about us!' },
        ]}
      >
        <Select placeholder="Select...">
          {options.map((option) => (
            <Option key={option} value={option}>{option}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

export default HowDidYouHear;
