import { Button, Card, Typography } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { JurisdictionInfo } from '../../shared/Types';
import UploadDocument from '../../shared/UploadDocument';
import UploadSelfie from '../../shared/UploadSelfie';
import UploadId from '../../shared/UploadId';

const { Text } = Typography;

export interface UploadImagesProps {
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  promptProAlternativeDoc: boolean;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  nextClicked: () => void;
  jurisdictionInfo: JurisdictionInfo;
}

export function UploadImages(props: UploadImagesProps) {
  const {
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    promptProAlternativeDoc,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    status,
    nextClicked,
    jurisdictionInfo,
  } = props;

  const imagesSelected = idFile !== undefined && selfieFile !== undefined;
  const buttonDisabled = !imagesSelected;

  if (status === 'process') {
    return (
      <>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ float: 'left' }}>
            <Text strong>Required Images</Text>
            <br />
            <Text>
              To complete your application with the State of
              {' '}
              {jurisdictionInfo.name}
              , we will need a
              copy of your driver&apos;s license and a selfie of you.
            </Text>
          </div>
        </Card>
        <div>
          <UploadSelfie selfieFile={selfieFile} setSelfieFile={setSelfieFile} />
          <br />
          <br />
          <UploadId idFile={idFile} setIdFile={setIdFile} jurisdictionInfo={jurisdictionInfo} />
          <br />
          <br />
          {promptProAlternativeDoc && (
            <>
              <UploadDocument
                docFile={proAlternativeDocFile}
                setDocFile={setProAlternativeDocFile}
                headerText={jurisdictionInfo.proAlternativeInfo!.supportingDocHeader}
                docDescriptions={jurisdictionInfo.proAlternativeInfo!.supportingDocDescription}
                eventName="Upload Supporting Pro Alternative Document"
              />
              <br />
              <br />
            </>
          )}
          <br />
          <Button
            disabled={buttonDisabled}
            type="primary"
            onClick={() => {
              nextClicked();
            }}
          >
            Next
          </Button>
        </div>
      </>
    );
  }

  if (status === 'finish') {
    return <Text strong>Done!</Text>;
  }

  return null;
}
