import React from 'react';
import { Form, Input } from 'antd';
import { Focused } from 'react-credit-cards';

export interface BillingNameInputProps {
  setFocus: (field: Focused) => void;
}

function BillingNameInput(props: BillingNameInputProps) {
  const { setFocus } = props;

  return (
    <Form.Item
      name="billing_name"
      rules={[{ required: true, message: 'Name on Card is required' }]}
    >
      <Input onFocus={() => setFocus('name')} placeholder="Name on Card" />
    </Form.Item>
  );
}

export default BillingNameInput;
