import { RcFile } from 'antd/lib/upload';

const apiHostname = process.env.REACT_APP_API_HOSTNAME;

const uploadId = async (appointmentId: string, appointmentKey: string, file: RcFile) => {
  const idSAS = await fetch(`${apiHostname}/api/UploadImage/${appointmentId}/id`, {
    headers: {
      authorization: appointmentKey,
    },
  });

  const uploadUrl = await idSAS.json();
  await fetch(uploadUrl.uploadURL, {
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-blob-content-type': file.type,
    },
    body: file,
  });
};

const uploadSelfie = async (appointmentId: string, appointmentKey: string, file: RcFile) => {
  const idSAS = await fetch(`${apiHostname}/api/UploadImage/${appointmentId}/selfie`, {
    headers: {
      authorization: appointmentKey,
    },
  });

  const uploadUrl = await idSAS.json();
  await fetch(uploadUrl.uploadURL, {
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-blob-content-type': file.type,
    },
    body: file,
  });
};

const uploadproAlternativeDoc = async (
  appointmentId: string,
  appointmentKey: string,
  file: RcFile,
) => {
  const idSAS = await fetch(`${apiHostname}/api/UploadImage/${appointmentId}/proalternativedoc`, {
    headers: {
      authorization: appointmentKey,
    },
  });

  const uploadUrl = await idSAS.json();
  await fetch(uploadUrl.uploadURL, {
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
      'x-ms-blob-content-type': file.type,
    },
    body: file,
  });
};

export { uploadId, uploadSelfie, uploadproAlternativeDoc };
