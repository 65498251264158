import React from 'react';
import { Form, Input } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

function EmailInput() {
  return (
    <Form.Item label="E-mail" required>
      <Input.Group>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid email!',
            },
            {
              required: true,
              message: 'Please input your email.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="emailPermission" valuePropName="checked">
          <Checkbox>
            Yes, you may email and text me information about your services.
          </Checkbox>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
}

export default EmailInput;
