import React from 'react';

function ConfirmationTitle() {
  return (
    <span style={{ float: 'left' }}>
      <div className="ant-steps-item-icon">
        <span className="ant-steps-icon">4</span>
      </div>
      Confirmation
    </span>
  );
}

export default ConfirmationTitle;
