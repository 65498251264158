import { Button, Col, Row, Spin, Typography } from "antd";
import currency from "currency.js";
import React, { useState } from "react";
import { JurisdictionInfo } from "../../shared/Types";
import Agreement from "./Agreement";

const { Text } = Typography;

export interface FinalizeProps {
  upgradeOption?: "patient" | "cultivation";
  proAlternative: boolean;
  upgradeSixteenOz: boolean;
  isSubmitting: boolean;
  status: "wait" | "process" | "finish" | "error" | undefined;
  onFinish: () => void;
  jurisdictionInfo: JurisdictionInfo;
}

export function Finalize(props: FinalizeProps) {
  const {
    upgradeOption,
    proAlternative,
    upgradeSixteenOz,
    isSubmitting,
    status,
    onFinish,
    jurisdictionInfo,
  } = props;
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);

  const onClick = () => {
    onFinish();
  };

  const buttonDisabled = isSubmitting || !agreementAccepted;

  if (status === "process") {
    return (
      <div>
        <br />
        {upgradeOption === "patient" && (
          <>
            Upgrade to
            <i> PRO Patient!</i>:{" "}
            {currency(
              proAlternative
                ? jurisdictionInfo.proAlternativeInfo!.price
                : jurisdictionInfo.proPrice
            ).format()}
          </>
        )}
        {upgradeOption === "cultivation" && (
          <>
            Upgrade to
            <i> PRO Cultivation!</i>:{" "}
            {currency(
              (proAlternative
                ? jurisdictionInfo.proAlternativeInfo!.price
                : jurisdictionInfo.proPrice) +
                jurisdictionInfo.proCultivationPrice
            ).format()}
          </>
        )}
        {upgradeSixteenOz && (
          <>
            <br />
            <>
              Upgrade to
              <i> 16oz!</i>:{" "}
              {currency(jurisdictionInfo.sixteenOzUpgradePrice).format()}
            </>
          </>
        )}
        <br />
        <Row align="middle">
          <Col>
            <br />
            <Agreement
              agreementAccepted={agreementAccepted}
              setAgreementAccepted={setAgreementAccepted}
            />
            <br />
            <br />
            <Button disabled={buttonDisabled} type="primary" onClick={onClick}>
              Finalize
            </Button>
          </Col>
          {isSubmitting && (
            <Col>
              <Spin style={{ margin: "5px 0px 0px 5px" }} />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  if (status === "finish") {
    return <Text strong>Done!</Text>;
  }

  return null;
}
