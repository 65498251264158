import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

interface CountySelectProps {
  counties: string[];
}

function CountySelect(props: CountySelectProps) {
  const { counties } = props;
  return (
    <Form.Item
      name="county"
      label="County of Residence"
    >
      <Select placeholder="Select County">
        {counties.map((county) => (
          <Option key={county} value={county}>{county}</Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default CountySelect;
