import React from "react";
import { jurisdictionInfo } from "./shared/Constants";
import Upgrade from "./Upgrade";

const upgradeText = ["Please choose your upgrade option below."];

function OklahomaUpgrade() {
  return (
    <Upgrade jurisdictionInfo={jurisdictionInfo.OK} upgradeText={upgradeText} />
  );
}

export default OklahomaUpgrade;
