import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';
// import { getAppInsights } from '../../shared/TelemetryService';

export interface PaymentInformationTitleProps {
  setCurrent: Function;
  isEditable: boolean;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
}

export function PaymentInformationTitle(state: PaymentInformationTitleProps) {
  const { status, setCurrent, isEditable } = state;
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (status === 'process') {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [status]);

  return (
    <>
      <span ref={ref} style={{ float: 'left' }}>
        <div className="ant-steps-item-icon">
          <span className="ant-steps-icon">3</span>
        </div>
        Payment Information
      </span>
      {status === 'finish' && isEditable
        && (
          <span style={{ float: 'right' }}>
            <Button
              title="Click"
              onClick={() => {
                // getAppInsights().trackEvent({ name: 'Go back to Payment Information' });
                setCurrent(3);
              }}
            >
              Change
            </Button>
          </span>
        )}
    </>
  );
}
