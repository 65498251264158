import React from 'react';
import { Form, Input } from 'antd';

function GroupItem({ name, label }: { name: string, label: string }) {
  return (
    <Form.Item
      name={['address', name]}
      noStyle
      rules={[{ required: true, message: `${label} is required` }]}
    >
      <Input placeholder={label} />
    </Form.Item>
  );
}

function MailingAddressGroup() {
  return (
    <Form.Item label="Mailing Address" required>
      <Form.Item
        name={['address', 'street1']}
        rules={[
          { required: true, message: 'Address is required so we can mail you your ID!' },
        ]}
      >
        <Input placeholder="Street 1" />
      </Form.Item>

      <Form.Item
        name={['address', 'street2']}
      >
        <Input placeholder="Street 2" />
      </Form.Item>
      <Input.Group compact>
        <GroupItem name="city" label="City" />
        <GroupItem name="state" label="State" />
        <GroupItem name="zip" label="Zip" />
      </Input.Group>
    </Form.Item>
  );
}

export default MailingAddressGroup;
