import { Steps } from "antd";
import { StepProps } from "antd/lib/steps";
import { RcFile } from "antd/lib/upload";
import React from "react";

import {
  BillingInformation,
  JurisdictionInfo,
  RenewalType,
} from "../../shared/Types";
import { Finalize, FinalizeProps } from "./Main";
import { FinalizeTitle } from "./Title";

const { Step } = Steps;

export interface FinalizeStepProps {
  isSubmitting: boolean;
  billingInformation: BillingInformation | undefined;
  idFile: RcFile | undefined;
  setIdFile: (file: RcFile) => void;
  selfieFile: RcFile | undefined;
  setSelfieFile: (file: RcFile) => void;
  proAlternativeDocFile: RcFile | undefined;
  setProAlternativeDocFile: (file: RcFile) => void;
  finalizeClicked: (
    proService: boolean,
    proCultivationService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => void;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
  renewalType: RenewalType;
}

export function FinalizeStep(props: FinalizeStepProps & StepProps) {
  const {
    isSubmitting,
    billingInformation,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    finalizeClicked,
    jurisdictionInfo,
    upgradeText,
    renewalType,
    ...stepProps
  } = props;
  const { status } = stepProps;

  const finalizeProps: FinalizeProps = {
    isSubmitting,
    billingInformation,
    status,
    onFinish: finalizeClicked,
    jurisdictionInfo,
    idFile,
    setIdFile,
    selfieFile,
    setSelfieFile,
    proAlternativeDocFile,
    setProAlternativeDocFile,
    upgradeText,
    renewalType,
  };

  return (
    <Step
      key="Finalize"
      title={FinalizeTitle()}
      description={Finalize(finalizeProps)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stepProps}
    />
  );
}
