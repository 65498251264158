const OhioCounties = [
  'Adams County',
  'Allen County',
  'Ashland County',
  'Ashtabula County',
  'Athens County',
  'Auglaize County',
  'Belmont County',
  'Brown County',
  'Butler County',
  'Carroll County',
  'Champaign County',
  'Clark County',
  'Clermont County',
  'Clinton County',
  'Columbiana County',
  'Coshocton County',
  'Crawford County',
  'Cuyahoga County',
  'Darke County',
  'Defiance County',
  'Delaware County',
  'Erie County',
  'Fairfield County',
  'Fayette County',
  'Franklin County',
  'Fulton County',
  'Gallia County',
  'Geauga County',
  'Greene County',
  'Guernsey County',
  'Hamilton County',
  'Hancock County',
  'Hardin County',
  'Harrison County',
  'Henry County',
  'Highland County',
  'Hocking County',
  'Holmes County',
  'Huron County',
  'Jackson County',
  'Jefferson County',
  'Knox County',
  'Lake County',
  'Lawrence County',
  'Licking County',
  'Logan County',
  'Lorain County',
  'Lucas County',
  'Madison County',
  'Mahoning County',
  'Marion County',
  'Medina County',
  'Meigs County',
  'Mercer County',
  'Miami County',
  'Monroe County',
  'Montgomery County',
  'Morgan County',
  'Morrow County',
  'Muskingum County',
  'Noble County',
  'Ottawa County',
  'Paulding County',
  'Perry County',
  'Pickaway County',
  'Pike County',
  'Portage County',
  'Preble County',
  'Putnam County',
  'Richland County',
  'Ross County',
  'Sandusky County',
  'Scioto County',
  'Seneca County',
  'Shelby County',
  'Stark County',
  'Summit County',
  'Trumbull County',
  'Tuscarawas County',
  'Union County',
  'Van Wert County',
  'Vinton County',
  'Warren County',
  'Washington County',
  'Wayne County',
  'Williams County',
  'Wood County',
  'Wyandot County',
];

export default OhioCounties;