import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { BookingInformation, JurisdictionInfo } from '../../shared/Types';

const { Text } = Typography;

export interface ConfirmationProps {
  appointment: Date;
  bookingInformation: BookingInformation;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  jurisdictionInfo: JurisdictionInfo;
}

export function Confirmation(props: ConfirmationProps) {
  const {
    appointment, bookingInformation, status, jurisdictionInfo,
  } = props;
  const { name } = bookingInformation;
  const { first, last } = name || {};
  const displayName = `${first} ${last}`;
  const appointmentTime = moment(appointment).format('LLLL');
  const displayAppointment = `${appointmentTime} (25 minutes)`;

  const message1 = `
    From your Smartphone or computer equipped with a camera you simply click on the link provided 
    below at the time of your appointment. This will launch in your browser. You will be asked to 
    enter you name and then you will join the physician online!`;

  const message2 = '-- No Installs or Phone calls required. Simply click the link above to connect!--';

  if (status === 'process') {
    return (
      <>
        <Row align="middle">
          <Col
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 10, offset: 7 }}
            style={{ textAlign: 'center' }}
          >
            <span className="success">Success!</span>
            <br />
            <Text strong>{displayName}</Text>
            <br />
            <Text strong>Your Appointment Has Been Confirmed</Text>
            <br />
            <Text style={{ fontStyle: 'italic' }}>
              For your privacy all billing on credit cards processed by 420ID will be under the name
              Pro Medical Reviews
            </Text>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col xs={6} sm={8} style={{ textAlign: 'right' }}>
            <Text strong>What</Text>
          </Col>
          <Col xs={18} sm={16}>
            <Text>
              {jurisdictionInfo.name}
              {' '}
              Medical Green Card Physician Review
            </Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={6} sm={8} style={{ textAlign: 'right' }}>
            <Text strong>When</Text>
          </Col>
          <Col xs={18} sm={16}>
            <Text>{displayAppointment}</Text>
          </Col>
        </Row>
        <Row>
          <Col sm={{ offset: 4, span: 16 }} style={{ textAlign: 'center' }}>
            <br />
            <Text strong style={{ color: '#1890ff' }}>
              HOW TO CONNECT WITH THE PHYSICIAN
            </Text>
            <br />
            <br />
            {message1}
            <br />
            <br />
            <a
              style={{ fontSize: '20px' }}
              target="_blank"
              rel="noopener noreferrer"
              href={jurisdictionInfo.reviewUrl}
            >
              {jurisdictionInfo.reviewUrl}
            </a>
            <br />
            <br />
            <Text strong>{message2}</Text>
            <br />
            <br />
          </Col>
        </Row>
      </>
    );
  }

  return null;
}
