// import { AvailabilityDate } from '@420id/types';
import { Card, Col, Row, Spin, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GetAvailability } from "../ApiWrapper";
import { JurisdictionInfo } from "../Types";
import PageControl from "./PageControls";
import TimeListItem from "./TimeListItem";
import useMedia from "./useMedia";

export interface AvailabilityDate {
  date: string;
  times: string[];
}

export interface Availability {
  dates: AvailabilityDate[];
  hasNextPage: boolean;
}

const { Text } = Typography;

export interface ChooseAppointmentProps {
  appointment: Date;
  status: "wait" | "process" | "finish" | "error" | undefined;
  timeSelected: (appointment: Date) => void;
  jurisdictionInfo: JurisdictionInfo;
}

export function ChooseAppointment(props: ChooseAppointmentProps) {
  const { appointment, status, timeSelected, jurisdictionInfo } = props;
  const [availability, setAvailability] = useState<AvailabilityDate[]>();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [tempSelection, setTempSelection] = useState<Date>();

  const columnCount = useMedia(["(min-width: 576px)"], [5], 3);

  useEffect(() => {
    const getData = async () => {
      const result = await GetAvailability(
        columnCount,
        currentPage,
        jurisdictionInfo.abbr
      );
      const { dates, hasNextPage: hasNextPageResult } = result;
      setAvailability(dates);
      setHasNextPage(hasNextPageResult);
      setLoading(false);
    };

    setLoading(true);
    getData();
  }, [columnCount, currentPage, jurisdictionInfo.abbr]);

  if (status === "process") {
    return (
      <>
        <Card>
          <div style={{ float: "left" }}>
            <Text strong>
              {jurisdictionInfo.name} Medical Green Card Physician Review
            </Text>
            <br />
            <Text>Click on a Time Below to Choose Your Appointment Time</Text>
          </div>
        </Card>
        {loading && (
          <Row>
            <Col
              xs={24}
              style={{ margin: "10px 0px 10px 0px", textAlign: "center" }}
            >
              <Spin />
            </Col>
          </Row>
        )}
        {!loading && (
          <>
            <PageControl
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              hasNextPage={hasNextPage}
            />
            <Row>
              {(availability || []).map((day) => {
                const openDay = day.date;
                return (
                  <Col key={openDay} flex={1} style={{ textAlign: "center" }}>
                    <div style={{ background: "#f6f6f6" }}>
                      <Text style={{ fontWeight: "bold" }}>
                        {moment(openDay).format("dddd")}
                      </Text>
                      <br />
                      <Text>{moment(openDay).format("MMMM DD")}</Text>
                    </div>
                    <div>
                      {day.times.map((time) => (
                        <TimeListItem
                          tempSelection={tempSelection}
                          setTempSelection={setTempSelection}
                          key={time}
                          timeSelected={timeSelected}
                          time={time}
                        />
                      ))}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </>
    );
  }

  if (appointment.valueOf() !== 0) {
    const appointmentTime = `${appointment.toDateString()} ${appointment.toLocaleTimeString()}`;

    return (
      <>
        <Text strong>
          {jurisdictionInfo.name} Medical Green Card Physician Review{" "}
        </Text>
        <Text>{appointmentTime}</Text>
      </>
    );
  }

  return null;
}
