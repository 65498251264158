import { RcFile } from "antd/lib/upload";
import ReactPixel from "react-facebook-pixel";
import { ScheduleAppointment } from "./ApiWrapper";
import { uploadId, uploadproAlternativeDoc, uploadSelfie } from "./ImageUtils";
// import { getAppInsights } from './TelemetryService';
import {
  BillingInformation,
  BookingInformation,
  JurisdictionInfo,
  ScheduleAppointmentDetails,
} from "./Types";

export interface BookAppointmentParams {
  setCurrent: Function;
  appointment: Date;
  setIsSubmitting: (isSubmitting: boolean) => void;
  billingInformation: BillingInformation | undefined;
  setPaymentError: Function;
  setSubmitted: (submitted: boolean) => void;
  // recordsFile: RcFile | undefined;
  bookingInformation: BookingInformation;
  idFile: RcFile | undefined;
  selfieFile: RcFile | undefined;
  proAlternativeDocFile: RcFile | undefined;
  proService: boolean;
  proAlternativeService: boolean;
  sixteenOzService: boolean;
  jurisdictionInfo: JurisdictionInfo;
  source?: string;
}

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

async function BookAppointment(params: BookAppointmentParams) {
  const {
    appointment,
    setIsSubmitting,
    billingInformation,
    setSubmitted,
    bookingInformation,
    proService,
    proAlternativeService,
    sixteenOzService,
    idFile,
    selfieFile,
    proAlternativeDocFile,
    jurisdictionInfo,
    source,
  } = params;
  try {
    // getAppInsights().trackEvent({
    //   name: 'Booking Appointment Started',
    //   properties: { proService, proAlternativeService },
    // });
    setIsSubmitting(true);
    const { billingName, certificate, cvc, howDidYouHear } =
      billingInformation!;
    const number = billingInformation!.cardNumber;
    const expiry =
      billingInformation!.expiryMonth +
      billingInformation!.expiryYear.substring(2, 4);
    const { first: firstName, last: lastName } = bookingInformation.name;
    const {
      street1: address1,
      street2: address2,
      city,
      state: addressState,
      zip,
    } = bookingInformation.address;
    const { phone, email, county } = bookingInformation;
    const appointmentDate = appointment;

    const scheduleAppointmentDetails: ScheduleAppointmentDetails = {
      appointmentDate,
      firstName,
      lastName,
      phone: clearNumber(phone),
      email,
      county,
      certificate,
      number,
      billingName,
      expiry,
      cvc,
      address1,
      address2,
      city,
      state: addressState,
      zip,
      proService,
      proAlternativeService,
      sixteenOzService,
      source,
      howDidYouHear,
      jurisdiction: jurisdictionInfo.abbr,
    };

    const result = await ScheduleAppointment(scheduleAppointmentDetails);

    if (idFile) {
      uploadId(result.appointmentId, result.key, idFile);
    }
    if (selfieFile) {
      uploadSelfie(result.appointmentId, result.key, selfieFile);
    }
    if (proAlternativeDocFile) {
      uploadproAlternativeDoc(
        result.appointmentId,
        result.key,
        proAlternativeDocFile
      );
    }
    setSubmitted(true);
    // getAppInsights().trackEvent({
    //   name: 'Booking Appointment Succeeded',
    //   properties: { proService, proAlternativeService },
    // });

    // iheart
    window.gtag("event", "conversion", {
      send_to: "AW-764361253/Za-9CLzNieICEKX0vOwC",
    });
    window.dataLayer.push({ event: "Appointment Scheduled" });

    // mantis
    window.mantis.push(["analytics", "event", "appointment_scheduled"]);

    // eslint-disable-next-line no-underscore-dangle
    window._tfa.push({ notify: "event", name: "make_purchase", id: 1141367 });

    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };

    ReactPixel.init("1783230948359199", undefined, options);
    ReactPixel.track("Purchase", "0");

    params.setCurrent(5);
  } catch (error) {
    params.setPaymentError(true);
    console.log(error);
    // getAppInsights().trackEvent({
    //   name: 'Booking Appointment Failed',
    //   properties: { proService, proAlternativeService },
    // });
    params.setCurrent(3);
  } finally {
    setIsSubmitting(false);
  }
}

export default BookAppointment;
