import { Card, Col, Row, Spin, Steps, Typography } from "antd";
import { Store } from "antd/lib/form/interface";
import currency from "currency.js";
import { RcFile } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppointmentImage from "../appointmentType-thumb.png";

// import './Schedule.css';
import { BillingInformation, JurisdictionInfo } from "../shared/Types";
import UpgradeLogo from "../upgradelogo.png";
// import { getAppInsights } from '../shared/TelemetryService';
import { CheckUpgradeToken } from "../shared/ApiWrapper";
import { BookUpgrade, BookUpgradeParams } from "../shared/BookUpgrade";
import ConfirmationStep from "./Confirmation";
import { FinalizeStep } from "./Finalize";
import PaymentInformationStep from "./PaymentInformation";
import SelectOptionStep from "./SelectOption";
import UploadImagesStep from "./UploadImages";

const { Text } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const defaultBillingInformation: BillingInformation = {
  cardNumber: "",
  billingName: "",
  expiryMonth: "",
  expiryYear: "",
  cvc: "",
  certificate: "",
  howDidYouHear: "",
};

function Upgrade({
  jurisdictionInfo,
  upgradeText,
}: {
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
}) {
  const headerText = `
    PRO SERVICES are designed for those who prefer assistance completing the final step of submitting the medical 
    approval and all required pictures to the state. Completing the state application on a mobile phone is difficult 
    so this upgrade option is highly recommended if you are uncomfortable with technology, don’t have a desktop or 
    laptop computer or simply prefer to have everything completed for you for convenience. PRO SERVICES INCLUDE ALL STATE APPLICATION FEES.
  `;

  const query = useQuery();
  const token = query.get("token")!;
  const appointmentId = query.get("appointmentId")!;

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [idFile, setIdFile] = useState<RcFile>();
  const [selfieFile, setSelfieFile] = useState<RcFile>();
  const [proAlternativeDocFile, setProAlternativeDocFile] = useState<RcFile>();
  const [proAlternative, setProAlternative] = useState(false);
  const [upgradeOption, setUpgradeOption] = useState<
    "patient" | "cultivation"
  >();
  const [upgradeSixteenOz, setUpgradeSixteenOz] = useState(false);

  const [paymentError, setPaymentError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [billingInformation, setBillingInformation] =
    useState<BillingInformation>(defaultBillingInformation);
  const [submitted, setSubmitted] = useState<boolean>(false);

  // TODO: remove ! from above two lines and setup redirect for when data is missing

  useEffect(() => {
    const getData = async () => {
      const result = await CheckUpgradeToken(appointmentId, token);
      if (!result) {
        setTokenError(true);
      }
      setLoading(false);
    };

    setLoading(true);
    getData();
  }, [appointmentId, token]);

  const paymentFormFinish = (values: Store, discount: currency) => {
    const billInfo: BillingInformation = {
      cardNumber: values.number.replace(/ /g, ""),
      billingName: values.billing_name,
      expiryMonth: values.expiryMonth,
      expiryYear: values.expiryYear,
      cvc: values.cvc,
      certificate: values.certificate,
      discountAmount: discount,
      howDidYouHear: "",
    };
    setBillingInformation(billInfo);
    setPaymentError(false);
    setCurrent(upgradeOption === undefined ? 2 : 3);
  };

  const finishSelectOption = (values: {
    upgradeOption: "patient" | "cultivation";
    proAlternative: boolean;
    upgradePatient: boolean;
    upgradeCultivation: boolean;
    upgradeSixteenOz: boolean;
  }) => {
    if (values.upgradePatient) {
      setUpgradeOption("patient");
    } else if (values.upgradeCultivation) {
      setUpgradeOption("cultivation");
    } else {
      setUpgradeOption(values.upgradeOption);
    }
    setUpgradeSixteenOz(values.upgradeSixteenOz);
    setProAlternative(values.proAlternative);
    setCurrent(1);
  };

  const finalizeAppointmentClicked = () => {
    const bookUpgradeParams: BookUpgradeParams = {
      appointmentId,
      proUpgradeOption: upgradeOption,
      proAlternative,
      upgradeSixteenOz,
      setCurrent,
      setIsSubmitting,
      idFile: idFile,
      selfieFile: selfieFile,
      proAlternativeDocFile,
      token: token || "",
      billingInformation,
      setPaymentError,
      setSubmitted,
      jurisdictionInfo,
    };
    BookUpgrade(bookUpgradeParams);
  };

  return (
    <Row>
      <Col xl={{ offset: 6, span: 12 }}>
        <Card>
          <Row>
            <Col style={{ textAlign: "center", margin: "auto" }}>
              <img src={UpgradeLogo} alt="Logo" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 4 }}>
              <img
                src={AppointmentImage}
                alt=""
                id="doctor-image"
                style={{ width: "150px" }}
              />
            </Col>
            <Col xl={{ span: 20 }}>
              <div style={{ paddingTop: "25px" }}>
                <Text strong>Upgrade to Pro Service!</Text>
                <br />
                <br />
                <Text>{headerText}</Text>
                <br />
                <br />
              </div>
            </Col>
          </Row>
        </Card>
        {loading && (
          <Row>
            <Col
              xs={24}
              style={{ margin: "10px 0px 10px 0px", textAlign: "center" }}
            >
              <Spin />
            </Col>
          </Row>
        )}
        {!loading && tokenError && (
          <Row>
            <Col
              xs={24}
              style={{ margin: "10px 0px 10px 0px", textAlign: "center" }}
            >
              <div>
                There was an error loading your link. This appointment may have
                already been upgraded or the submission window has already
                passed Please contact us for more information.
              </div>
            </Col>
          </Row>
        )}
        {!loading && !tokenError && (
          <Card className="bodyWrapper">
            <Steps direction="vertical" current={current} className="steps">
              <SelectOptionStep
                upgradeOption={upgradeOption}
                proAlternative={proAlternative}
                onFinish={finishSelectOption}
                jurisdictionInfo={jurisdictionInfo}
                upgradeText={upgradeText}
              />
              {(upgradeOption === "patient" ||
                upgradeOption === "cultivation") && (
                <UploadImagesStep
                  idFile={idFile}
                  setIdFile={setIdFile}
                  selfieFile={selfieFile}
                  setSelfieFile={setSelfieFile}
                  promptProAlternativeDoc={proAlternative}
                  proAlternativeDocFile={proAlternativeDocFile}
                  setProAlternativeDocFile={setProAlternativeDocFile}
                  nextClicked={() => {
                    setCurrent(2);
                  }}
                  jurisdictionInfo={jurisdictionInfo}
                />
              )}
              <PaymentInformationStep
                setCurrent={setCurrent}
                isSubmitting={isSubmitting}
                submitted={submitted}
                paymentError={paymentError}
                setPaymentError={setPaymentError}
                billingInformation={billingInformation}
                setBillingInformation={setBillingInformation}
                paymentFormFinish={paymentFormFinish}
                jurisdictionInfo={jurisdictionInfo}
              />
              <FinalizeStep
                upgradeOption={upgradeOption!}
                upgradeSixteenOz={upgradeSixteenOz}
                proAlternative={proAlternative}
                isSubmitting={isSubmitting}
                finalizeClicked={finalizeAppointmentClicked}
                jurisdictionInfo={jurisdictionInfo}
              />
              <ConfirmationStep />
            </Steps>
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default Upgrade;
