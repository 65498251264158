import { Card, Col, Row, Steps, Typography } from "antd";
import { Store } from "antd/lib/form/interface";
import { RcFile } from "antd/lib/upload";
import currency from "currency.js";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AppointmentImage from "../appointmentType-thumb.png";

// import './Schedule.css';
import RenewLogo from "../renewlogo.png";
import RapidReturnLogo from "../rapidReturnLogo.png";
import { BookRenewal, BookRenewalParams } from "../shared/BookRenewal";
import { ChooseAppointmentStep } from "../shared/ChooseAppointment";
// import { getAppInsights } from '../shared/TelemetryService';
import {
  BillingInformation,
  BookingInformation,
  JurisdictionInfo,
  RenewalType,
} from "../shared/Types";
import ConfirmationStep from "./Confirmation";
import { ConfirmEmailStep } from "./ConfirmEmail";
import { FinalizeStep } from "./Finalize";
import PaymentInformationStep from "./PaymentInformation";
import { QuestionnaireStep } from "./Questionnaire";
import { Questions } from "./Questionnaire/Main";
import YourInformationStep from "./YourInformation";

const { Text } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const defaultBookingInfo: BookingInformation = {
  name: {
    first: "",
    middle: "",
    last: "",
  },
  email: "",
  address: {
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  },
  phone: "",
  county: "",
  jurisdiction: "",
};

const defaultQuestions: Questions = {
  sameCondition: undefined,
  didItHelp: undefined,
  hasNewConditions: undefined,
  newConditions: undefined,
  hasSideEffects: undefined,
  sideEffects: undefined,
};

const defaultBillingInformation: BillingInformation = {
  cardNumber: "",
  billingName: "",
  expiryMonth: "",
  expiryYear: "",
  cvc: "",
  certificate: "",
  howDidYouHear: "",
};

function Renewal({
  headerText,
  smallPrint,
  jurisdictionInfo,
  upgradeText,
  renewalType,
}: {
  headerText: string;
  smallPrint: string;
  jurisdictionInfo: JurisdictionInfo;
  upgradeText: string[];
  renewalType: RenewalType;
}) {
  const query = useQuery();
  const token = query.get("token");

  const [current, setCurrent] = useState(0);
  const [confirmationEmail, setConfirmationEmail] = useState<string>("");
  const [paymentError, setPaymentError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingInformation, setBookingInformation] =
    useState<BookingInformation>(defaultBookingInfo);
  const [questionnaire, setQuestionnaire] =
    useState<Questions>(defaultQuestions);
  const [appointment, setAppointment] = useState(new Date(0));
  const [billingInformation, setBillingInformation] =
    useState<BillingInformation>(defaultBillingInformation);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [idFile, setIdFile] = useState<RcFile>();
  const [selfieFile, setSelfieFile] = useState<RcFile>();
  const [proAlternativeDocFile, setProAlternativeDocFile] = useState<RcFile>();

  const confirmEmailFinish = (email: string) => {
    const bookingInfo: BookingInformation = { ...bookingInformation, email };
    setConfirmationEmail(email);
    setBookingInformation(bookingInfo);
    setCurrent(1);
  };

  const questionnaireFinish = (questions: Questions) => {
    setQuestionnaire(questions);
    setCurrent(2);
  };

  const isEditable = !isSubmitting && !submitted;

  const appointmentChosen = (selectedAppointment: Date) => {
    setAppointment(selectedAppointment);
    setCurrent(2);
  };

  const paymentFormFinish = (values: Store, discount: currency) => {
    const billInfo: BillingInformation = {
      cardNumber: values.number.replace(/ /g, ""),
      billingName: values.billing_name,
      expiryMonth: values.expiryMonth,
      expiryYear: values.expiryYear,
      cvc: values.cvc,
      certificate: values.certificate,
      discountAmount: discount,
      howDidYouHear: "",
    };
    setBillingInformation(billInfo);
    // getAppInsights().trackEvent({ name: 'Submitted Payment Information' });
    setPaymentError(false);
    setCurrent(4);
  };

  const finalizeAppointmentClicked = (
    proService: boolean,
    proCultivationService: boolean,
    proAlternativeService: boolean,
    sixteenOzService: boolean
  ) => {
    const bookRenewalParams: BookRenewalParams = {
      setCurrent,
      setIsSubmitting,
      token: token || "",
      confirmEmail: confirmationEmail,
      billingInformation,
      setPaymentError,
      setSubmitted,
      bookingInformation,
      questions: questionnaire,
      idFile,
      selfieFile,
      proAlternativeDocFile,
      proService,
      proCultivationService,
      proAlternativeService,
      sixteenOzService,
      jurisdictionInfo,
      renewalType,
      appointmentDate: appointment,
    };
    BookRenewal(bookRenewalParams);
  };

  return (
    <Row>
      <Col xl={{ offset: 6, span: 12 }}>
        <Card>
          <Row>
            <Col style={{ textAlign: "center", margin: "auto" }}>
              {RenewalType.RapidReturn === renewalType ? (
                <img
                  width={300}
                  height={100}
                  src={RapidReturnLogo}
                  alt="Logo"
                />
              ) : (
                <img src={RenewLogo} alt="Logo" />
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 4 }}>
              <img
                src={AppointmentImage}
                alt=""
                id="doctor-image"
                style={{ width: "150px" }}
              />
            </Col>
            <Col xl={{ span: 20 }}>
              <div style={{ paddingTop: "25px" }}>
                <Text>
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: headerText }} />
                </Text>
                <Text style={{ verticalAlign: "sub", fontSize: "smaller" }}>
                  {smallPrint}
                </Text>
              </div>
            </Col>
          </Row>
        </Card>
        <Card className="bodyWrapper">
          <Steps direction="vertical" current={current} className="steps">
            <ConfirmEmailStep
              onFinish={confirmEmailFinish}
              email={confirmationEmail}
              token={token || ""}
              renewalType={renewalType}
              jurisdictionInfo={jurisdictionInfo}
            />
            {renewalType === RenewalType.Standard && (
              <QuestionnaireStep
                setCurrent={setCurrent}
                isEditable={!isSubmitting && !submitted}
                questionnaire={questionnaire}
                onFinish={questionnaireFinish}
              />
            )}
            {renewalType === RenewalType.RapidReturn && (
              <ChooseAppointmentStep
                setCurrent={setCurrent}
                appointmentChosen={appointmentChosen}
                appointment={appointment}
                isEditable={isEditable}
                jurisdictionInfo={jurisdictionInfo}
              />
            )}
            <YourInformationStep
              setCurrent={setCurrent}
              isSubmitting={isSubmitting}
              submitted={submitted}
              bookingInformation={bookingInformation}
              setBookingInformation={setBookingInformation}
              jurisdictionInfo={jurisdictionInfo}
            />
            <PaymentInformationStep
              setCurrent={setCurrent}
              isSubmitting={isSubmitting}
              submitted={submitted}
              paymentError={paymentError}
              setPaymentError={setPaymentError}
              billingInformation={billingInformation}
              setBillingInformation={setBillingInformation}
              paymentFormFinish={paymentFormFinish}
              jurisdictionInfo={jurisdictionInfo}
              renewalType={renewalType}
            />
            <FinalizeStep
              isSubmitting={isSubmitting}
              billingInformation={billingInformation}
              finalizeClicked={finalizeAppointmentClicked}
              jurisdictionInfo={jurisdictionInfo}
              idFile={idFile}
              setIdFile={setIdFile}
              selfieFile={selfieFile}
              setSelfieFile={setSelfieFile}
              proAlternativeDocFile={proAlternativeDocFile}
              setProAlternativeDocFile={setProAlternativeDocFile}
              upgradeText={upgradeText}
              renewalType={renewalType}
            />
            <ConfirmationStep bookingInformation={bookingInformation} />
          </Steps>
        </Card>
      </Col>
    </Row>
  );
}

export default Renewal;
