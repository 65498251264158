import LouisianaCounties from "./LouisianaCounties";
import MissouriCounties from "./MissouriCounties";
import OhioCounties from "./OhioCounties";
import OklahomaCounties from "./OklahomaCounties";
import PennsylvaniaCounties from "./PennsylvaniaCounties";
import { JurisdictionInfo } from "./Types";

// eslint-disable-next-line import/prefer-default-export
export const jurisdictionInfo: { [key: string]: JurisdictionInfo } = {
  LA: {
    abbr: "LA",
    name: "Louisiana",
    applicationFee: 50,
    reviewPrice: 150,
    reviewUrl: "https://pmr.doxy.me/louisiana",
    proUpgradeAvailable: false,
    proPrice: 150,
    proCultivationUpgradeAvailable: false,
    proCultivationPrice: 0,
    renewalPrice: 150,
    counties: LouisianaCounties,
    sixteenOzUpgradeAvailable: false,
    sixteenOzUpgradePrice: 0,
    returnPrice: 0,
  },
  MO: {
    abbr: "MO",
    name: "Missouri",
    applicationFee: 26.83,
    reviewPrice: 150,
    reviewUrl: "https://pmr.doxy.me/missouri",
    proUpgradeAvailable: true,
    proPrice: 50,
    proCultivationUpgradeAvailable: true,
    proCultivationPrice: 100,
    renewalPrice: 125,
    counties: MissouriCounties,
    sixteenOzUpgradeAvailable: false,
    sixteenOzUpgradePrice: 100,
    returnPrice: 95,
  },
  OK: {
    abbr: "OK",
    name: "Oklahoma",
    applicationFee: 100,
    reviewPrice: 150,
    reviewUrl: "https://pmr.doxy.me/oklahoma",
    proUpgradeAvailable: true,
    proPrice: 150,
    proCultivationUpgradeAvailable: false,
    proCultivationPrice: 0,
    renewalPrice: 150,
    proAlternativeInfo: {
      price: 70,
      prompt:
        "Are you on Medicare, Medicaid or are you a 100% disabled Veteran? Qualified patients receive an $80 discount on OK State application fees.",
      supportingDocHeader:
        "Please Upload Proof of Medicaid/Medicare/Disabled Veteran Status",
      supportingDocDescription: [
        "Medicare - front of your Medicare card",
        "Medicaid - enrollment letter",
        "Veteran Status - letter from the VA with 100% disability status noted",
      ],
    },
    counties: OklahomaCounties,
    sixteenOzUpgradeAvailable: false,
    sixteenOzUpgradePrice: 0,
    returnPrice: 0,
  },
  OH: {
    abbr: "OH",
    name: "Ohio",
    applicationFee: 0,
    reviewPrice: 150,
    reviewUrl: "https://pmr.doxy.me/ohio",
    proUpgradeAvailable: false,
    proPrice: 50,
    proCultivationUpgradeAvailable: false,
    proCultivationPrice: 0,
    renewalPrice: 100,
    counties: OhioCounties,
    sixteenOzUpgradeAvailable: false,
    sixteenOzUpgradePrice: 0,
    returnPrice: 0,
  },
  PA: {
    abbr: "PA",
    name: "Pennsylvania",
    applicationFee: 50,
    reviewPrice: 150,
    reviewUrl: "https://pmr.doxy.me/pennsylvania",
    proUpgradeAvailable: true,
    proPrice: 75,
    proCultivationUpgradeAvailable: false,
    proCultivationPrice: 0,
    renewalPrice: 100,
    counties: PennsylvaniaCounties,
    sixteenOzUpgradeAvailable: false,
    sixteenOzUpgradePrice: 0,
    returnPrice: 0,
  },
};
