import React from 'react';
import { Form, Input } from 'antd';

export interface CVCInputProps {
  setFocus: Function;
  handleInputChange: Function;
}

function CVCInput(props: CVCInputProps) {
  const { setFocus, handleInputChange } = props;

  return (
    <Form.Item
      name="cvc"
      validateTrigger={['onChange', 'onBlur']}
      rules={[
        { required: true, message: 'CVC is required' },
        {
          validateTrigger: 'onBlur', min: 3, max: 4, message: 'CVC must be 3 or 4 digits',
        },
      ]}
    >
      <Input onFocus={() => setFocus('cvc')} placeholder="CVC" onChange={() => handleInputChange('cvc')} />
    </Form.Item>
  );
}

export default CVCInput;
