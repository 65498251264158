import React from 'react';
import { Form, Input } from 'antd';

export interface NumberInputProps {
  validCardNum: boolean;
  setFocus: Function;
  handleInputChange: Function;
}

function NumberInput(props: NumberInputProps) {
  const { validCardNum, setFocus, handleInputChange } = props;

  return (
    <Form.Item
      name="number"
      validateTrigger={['onChange', 'onBlur']}
      rules={[{
        required: true, message: 'Credit Card Number is required',
      }, {
        validator: (rule, value) => {
          if (value === '' || validCardNum) return Promise.resolve();
          return Promise.reject(new Error('You have entered an invalid card number.'));
        },
        validateTrigger: 'onBlur',
      }]}
    >
      <Input onFocus={() => setFocus('number')} placeholder="Card Number" onChange={() => handleInputChange('number')} />
    </Form.Item>
  );
}

export default NumberInput;
