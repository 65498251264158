import React, { useState } from 'react';
import { StepProps } from 'antd/lib/steps';
import {
  Typography, Card, Form, Input, Radio,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import NextButton from './NextButton';

const { Text } = Typography;

export interface Questions {
  sameCondition: 'yes' | 'no' | undefined;
  didItHelp: 'yes' | 'no' | undefined;
  hasNewConditions: 'yes' | 'no' | undefined;
  newConditions: string | undefined;
  hasSideEffects: 'yes' | 'no' | undefined;
  sideEffects: string | undefined;
}

export interface QuestionnaireProps {
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  questionnaire: Questions | undefined;
  onFinish: (questions: Questions) => void;
}

export function Questionnaire(props: QuestionnaireProps & StepProps) {
  const { questionnaire, onFinish, ...stepProps } = props;
  const { status } = stepProps;

  const [hasNewConditions, setHasNewConditions] = useState<boolean>(false);
  const [hasSideEffects, setHasSideEffects] = useState<boolean>(false);

  const questionStore: Store = [];
  if (questionnaire) {
    questionStore['same-condition'] = questionnaire.sameCondition;
    questionStore['did-it-help'] = questionnaire.didItHelp;
    questionStore['has-new-conditions'] = questionnaire.hasNewConditions;
    questionStore['new-conditions'] = questionnaire.newConditions;
    questionStore['has-side-effects'] = questionnaire.hasSideEffects;
    questionStore['side-effects'] = questionnaire.sideEffects;
  }

  const finishFunc = (values: Store) => {
    onFinish({
      sameCondition: values['same-condition'],
      didItHelp: values['did-it-help'],
      hasNewConditions: values['has-new-conditions'],
      newConditions: values['new-conditions'],
      hasSideEffects: values['has-side-effects'],
      sideEffects: values['side-effects'],
    });
  };

  if (status === 'process') {
    return (
      <>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ float: 'left' }}>
            <Text strong>Renewal Questions.</Text>
            <br />
            <Text>Please answer the following questions.</Text>
          </div>
        </Card>
        <Form
          // labelCol={formItemLayout.labelCol}
          // wrapperCol={formItemLayout.wrapperCol}
          name="register"
          onFinish={finishFunc}
          initialValues={questionStore}
          onValuesChange={(changedValues) => {
            if (changedValues['has-new-conditions'] === 'yes') {
              setHasNewConditions(true);
            } else if (changedValues['has-new-conditions'] === 'no') {
              setHasNewConditions(false);
            } else if (changedValues['has-side-effects'] === 'yes') {
              setHasSideEffects(true);
            } else if (changedValues['has-side-effects'] === 'no') {
              setHasSideEffects(false);
            }
          }}
          scrollToFirstError
        >
          <Text>
            For renewing patients, the doctor will review your questionnaire
            and call you to discuss your care and complete your renewal.
          </Text>
          <br />
          <>
            <Text>Are you still affected by the same qualifying condition(s)?</Text>
            <br />
            <Form.Item
              name="same-condition"
              required
              rules={[
                { required: true, message: 'You must answer this question.' },
              ]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Text>Did Medical Marijuana help your condition(s)?</Text>
            <br />
            <Form.Item
              name="did-it-help"
              required
              rules={[
                { required: true, message: 'You must answer this question.' },
              ]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Text>Have you recently developed any new medical condition(s)?</Text>
            <br />
            <Form.Item
              name="has-new-conditions"
              required
              rules={[
                { required: true, message: 'You must answer this question.' },
              ]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="new-conditions"
              label="Please tell us about your new condition(s)."
              hidden={!hasNewConditions}
              rules={[
                { required: hasNewConditions, message: 'You must answer this question.' },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            <Text>
              Have you experienced any negative side effects from using medical marijuana?
            </Text>
            <br />
            <Form.Item
              name="has-side-effects"
              required
              rules={[
                { required: true, message: 'You must answer this question.' },
              ]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="side-effects"
              label="Please tell us about the side effect(s) you've experienced."
              hidden={!hasSideEffects}
              rules={[
                { required: hasSideEffects, message: 'You must answer this question.' },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </>

          <NextButton disabled={false} />

        </Form>
      </>
    );
  }

  if (status === 'finish') {
    return 'Using Questionnaire!';
  }

  return null;
}
