import {
  Card, Col, Form, Row, Typography,
} from 'antd';
import currency from 'currency.js';
import React, { useEffect, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import PromoCodeInput from '../../shared/PromoCodeInput';
import { BillingInformation, JurisdictionInfo } from '../../shared/Types';
import BillingNameInput from './BillingNameInput';
import CVCInput from './CVCInput';
import ExpiryMonthInput from './ExpiryMonthInput';
import ExpiryYearInput from './ExpiryYearInput';
import { formatCreditCardNumber, formatCVC } from './formatCardInfo';
import HowDidYouHear from './HowDidYouHear';
import NextButton from './NextButton';
import NumberInput from './NumberInput';
import PaymentAlert from './PaymentAlert';

const { Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export interface PaymentInformationProps {
  paymentError: boolean;
  billingInformation: BillingInformation;
  enablePromoCode: boolean;
  status: 'wait' | 'process' | 'finish' | 'error' | undefined;
  jurisdictionInfo: JurisdictionInfo;
}

export function PaymentInformation(props: PaymentInformationProps, paymentFormFinish: Function) {
  const {
    paymentError, enablePromoCode, status, billingInformation, jurisdictionInfo,
  } = props;

  const [focus, setFocus] = useState<Focused>();
  const [validCardNum, setValidCardNum] = useState(false);
  const [headerNumber, setHeaderNumber] = useState<string>();
  const [discount, setDiscount] = useState<currency>();
  const [cardIssuer, setCardIssuer] = useState<string>();
  const [paymentForm] = Form.useForm();

  useEffect(() => {
    if (status === 'process') {
      paymentForm.validateFields(['certificate']);
      paymentForm.setFieldsValue({
        certificate: paymentForm.getFieldValue('certificate'),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleInputChange = (fieldName: string) => {
    if (fieldName === 'number') {
      setHeaderNumber(paymentForm.getFieldValue('number'));
      paymentForm.setFieldsValue({
        number: formatCreditCardNumber(paymentForm.getFieldValue(fieldName)),
      });
    } else if (fieldName === 'cvc') {
      paymentForm.setFieldsValue({ cvc: formatCVC(paymentForm.getFieldValue(fieldName)) });
    }
  };

  if (status === 'process') {
    return (
      <>
        <Card style={{ marginBottom: '15px' }}>
          <div style={{ float: 'left' }}>
            <Text strong>Great! We&apos;re looking forward to seeing you!</Text>
            <br />
            <Text>How would you like to pay for your appointment?</Text>
          </div>
        </Card>
        <Row className="paymentWrapper">
          <Col xs={24} sm={12}>
            <Form
              form={paymentForm}
              name="payment"
              initialValues={{
                ...billingInformation,
                prefix: '86',
              }}
              scrollToFirstError
            >
              <Form.Item shouldUpdate>
                <Typography.Text>
                  {jurisdictionInfo.name}
                  {' '}
                  Review: $
                  {jurisdictionInfo.reviewPrice}
                  <br />
                </Typography.Text>
                {discount && (
                  <Typography.Text>
                    Discount:
                    {' '}
                    {discount?.format()}
                    <br />
                    Total:
                    {' '}
                    {currency(jurisdictionInfo.reviewPrice)
                      .subtract(discount || 0)
                      .format()}
                  </Typography.Text>
                )}
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Cards
                    cvc={paymentForm.getFieldValue('cvc') || ''}
                    expiry={
                      (paymentForm.getFieldValue('expiryMonth') || '')
                      + (paymentForm.getFieldValue('expiryYear') || '')
                    }
                    focused={focus}
                    name={paymentForm.getFieldValue('billing_name') || ''}
                    number={paymentForm.getFieldValue('number') || ''}
                    callback={(type, isValid) => {
                      setCardIssuer(type.issuer);
                      setValidCardNum(isValid);
                    }}
                  />
                )}
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={12}>
            <Form
              labelCol={formItemLayout.labelCol}
              wrapperCol={formItemLayout.wrapperCol}
              form={paymentForm}
              name="payment"
              onFinish={(values) => {
                paymentFormFinish(values, discount);
              }}
              initialValues={{
                prefix: '86',
              }}
              scrollToFirstError
            >
              {paymentError && <PaymentAlert />}
              <NumberInput
                setFocus={setFocus}
                handleInputChange={handleInputChange}
                validCardNum={validCardNum}
              />
              <BillingNameInput setFocus={setFocus} />
              <Row>
                <Col sm={8} xs={12} style={{ marginLeft: 'auto' }}>
                  <ExpiryMonthInput setFocus={setFocus} />
                </Col>
                <Col sm={8} xs={12} style={{ marginRight: 'auto' }}>
                  <ExpiryYearInput setFocus={setFocus} />
                </Col>
              </Row>
              <CVCInput setFocus={setFocus} handleInputChange={handleInputChange} />
              <PromoCodeInput
                setDiscount={setDiscount}
                setFocus={setFocus}
                enablePromoCode={enablePromoCode}
                jurisdictionInfo={jurisdictionInfo}
                appointmentType="MMJ"
              />
              <HowDidYouHear />
              <NextButton setFocus={setFocus} />
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  if (headerNumber) {
    const cardType = cardIssuer;
    const endingWith = headerNumber.substring(12);
    const displayText = `${cardType} ending with ${endingWith}`;
    return <Text strong>{displayText}</Text>;
  }

  return null;
}
