import currency from "currency.js";
import React from "react";
import { jurisdictionInfo } from "./shared/Constants";
import Upgrade from "./Upgrade";

const upgradeText = [
  `<i>Pro Service</i>. This option includes us completing your state application and paying your state application fee (${currency(
    jurisdictionInfo.MO.applicationFee
  ).format()}) on your behalf. This option is designed for anyone who is uncomfortable with computers and technology and would like extra assistance.`,
  `<i>16oz Upgrade</i>. This option includes a second physician certifcation which will allow you to possess up to 16oz.`,
];

function MissouriUpgrade() {
  return (
    <Upgrade jurisdictionInfo={jurisdictionInfo.MO} upgradeText={upgradeText} />
  );
}

export default MissouriUpgrade;
