const MissouriCounties = [
  'Adair',
  'Andrew',
  'Atchison',
  'Audrain',
  'Barry',
  'Barton',
  'Bates',
  'Benton',
  'Bollinger',
  'Boone',
  'Buchanan',
  'Butler',
  'Caldwell',
  'Callaway',
  'Camden',
  'Cape Girardeau',
  'Carroll',
  'Carter',
  'Cass',
  'Cedar',
  'Chariton',
  'Christian',
  'Clark',
  'Clay',
  'Clinton',
  'Cole',
  'Cooper',
  'Crawford',
  'Dade',
  'Dallas',
  'Daviess',
  'DeKalb',
  'Dent',
  'Douglas',
  'Dunklin',
  'Franklin',
  'Gasconade',
  'Gentry',
  'Greene',
  'Grundy',
  'Harrison',
  'Henry',
  'Hickory',
  'Holt',
  'Howard',
  'Howell',
  'Iron',
  'Jackson',
  'Jasper',
  'Jefferson',
  'Johnson',
  'Knox',
  'Laclede',
  'Lafayette',
  'Lawrence',
  'Lewis',
  'Lincoln',
  'Linn',
  'Livingston',
  'Macon',
  'Madison',
  'Maries',
  'Marion',
  'McDonald',
  'Mercer',
  'Miller',
  'Mississippi',
  'Moniteau',
  'Monroe',
  'Montgomery',
  'Morgan',
  'New Madrid',
  'Newton',
  'Nodaway',
  'Oregon',
  'Osage',
  'Ozark',
  'Pemiscot',
  'Perry',
  'Pettis',
  'Phelps',
  'Pike',
  'Platte',
  'Polk',
  'Pulaski',
  'Putnam',
  'Ralls',
  'Randolph',
  'Ray',
  'Reynolds',
  'Ripley',
  'Saint Charles',
  'Saint Clair',
  'Saint Francois',
  'Saint Louis City',
  'Saint Louis County',
  'Saline',
  'Schuyler',
  'Scotland',
  'Scott',
  'Shannon',
  'Shelby',
  'Ste. Genevieve',
  'Stoddard',
  'Stone',
  'Sullivan',
  'Taney',
  'Texas',
  'Vernon',
  'Warren',
  'Washington',
  'Wayne',
  'Webster',
  'Worth',
  'Wright',
];

export default MissouriCounties;
